import React from 'react';
import Accordion from '../Accordion/Accordion';
import { IAccordionTabsItemsProps } from '../Accordion/AccordionItem';

const items: IAccordionTabsItemsProps[] = [
  {
    id: '01',
    label: 'Collapsed Accordion',
    content:
      'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit.Sed posuere consectetur est at lobortis.Donec id elit non mi porta gravida at eget metus.Nullam quis risus eget urna mollis ornare vel eu leo.Maecenas faucibus mollis interdum. Etiam porta sem malesuada magna mollis euismod.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Aenean eu leo quam.Pellentesque ornare sem lacinia quam venenatis vestibulum.Cras justo odio, dapibus ac facilisis in, egestas eget quam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    id: '02',
    label: 'Expanded Accordion',
    content:
      'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit.Sed posuere consectetur est at lobortis.Donec id elit non mi porta gravida at eget metus.Nullam quis risus eget urna mollis ornare vel eu leo.Maecenas faucibus mollis interdum. Etiam porta sem malesuada magna mollis euismod.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Aenean eu leo quam.Pellentesque ornare sem lacinia quam venenatis vestibulum.Cras justo odio, dapibus ac facilisis in, egestas eget quam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
];

const Accordions = () => <Accordion items={items} />;

export default Accordions;
