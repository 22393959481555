import React from 'react';
import FullWidthImage from '../FullWidthImage/FullWidthImage';
import ThreeAcrossContainer from '../ThreeAcrossContainer/ThreeAcrossContainer';
import TwoColumnFullWidth from '../TwoColumnFullWidth/TwoColumnFullWidth';
import GenericFullWidthCallout from '../GenericFullWidthCallout/GenericFullWidthCallout';
import InternalPageNavigation from '../InternalPageNavigation/InternalPageNavigation';
import MessageBanner from '../MessageBanner/MessageBanner';
import TwoColumnImages from '../TwoColumnImages/TwoColumnImages';

interface IComponents {
  [key: string]: React.FC<any> | React.FunctionComponent<any>;
}
const Components: IComponents = {
  FullWidthImage: FullWidthImage,
  ThreeAcrossContainer: ThreeAcrossContainer,
  TwoColumnFullWidth: TwoColumnFullWidth,
  GenericFullWidthCallout: GenericFullWidthCallout,
  InternalPageNavigation: InternalPageNavigation,
  MessageBanner: MessageBanner,
  TwoColumnImages: TwoColumnImages,
};

export default Components;
