import React from 'react';
import styled from 'styled-components';
import {
  ContentRowFragment,
  TwoColumnFullWidthFragment,
} from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import ContentRow from '../ContentRow/ContentRow';

const TwoColumnFullWidth: React.FC<TwoColumnFullWidthFragment> = ({
  header,
  subheader,
  contentRows,
}) => (
  <Container>
    <Content>
      <Heading>
        <span>{subheader?.subheader}</span>
        <h2>{header?.header}</h2>
      </Heading>
      <Rows>
        {contentRows?.map((row) => (
          <StyledContentRow
            key={row?.id as string}
            {...(row as ContentRowFragment)}
          />
        ))}
      </Rows>
    </Content>
  </Container>
);

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.fiord};
  padding: 42px 0 50px;
  ${(props) => props.theme.mq.desktop} {
    padding: 76px 0 100px;
  }
`;

const Content = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.mq.desktop} {
    flex-direction: row;
  }
`;

const Heading = styled.div`
  flex: 1;
  padding-right: 40px;
  span {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 2px;
    color: ${(props) => props.theme.colors.white};
    text-transform: uppercase;
  }
  h2 {
    line-height: 1.33;
    color: ${(props) => props.theme.colors.white};
    margin: 0;
  }
`;

const Rows = styled.div`
  ${(props) => props.theme.mq.desktop} {
    max-width: 580px;
  }
`;

const StyledContentRow = styled(ContentRow)`
  padding-top: 33px;
  padding-bottom: 22px;
  &:not(:last-of-type) {
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  }
  ${(props) => props.theme.mq.desktop} {
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  }
  h3 {
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    line-height: 1.86;
    margin: 0 0 10px;
  }
  p {
    color: ${(props) => props.theme.colors.white};
  }
  a {
    color: ${(props) => props.theme.colors.ronchi};
    text-decoration: none;
  }
`;

export default TwoColumnFullWidth;
