import React from 'react';
import { Link } from 'gatsby';
import { LinkFragment } from '../../../gatsby-graphql';
import { getLinkTarget, getLinkTo, isExternalUrl } from '../../utils';

interface ISiteLinkProps extends LinkFragment {
  className?: string;
  role?: string;
  tabIndex?: number;
  'aria-expanded'?: boolean;
  'aria-haspopup'?: boolean;
  onMouseEnter?: React.MouseEventHandler<any> | undefined;
  onMouseLeave?: React.MouseEventHandler<any> | undefined;
  onKeyDown?: React.KeyboardEventHandler<any> | undefined;
}

const SiteLink = React.forwardRef<any, ISiteLinkProps>(
  ({ url, label, id, ...props }, ref) =>
    url ? (
      isExternalUrl(url) ? (
        <a
          id={`${id}-${props.className}`}
          href={getLinkTo(url)}
          target={getLinkTarget(url)}
          {...props}
          ref={ref}
        >
          {label}
        </a>
      ) : (
        <Link
          id={`${id}-${props.className}`}
          to={getLinkTo(url)}
          {...props}
          ref={ref}
        >
          {label}
        </Link>
      )
    ) : (
      <span id={`${id}-${props.className}`} {...props} ref={ref}>
        {label}
      </span>
    )
);

SiteLink.displayName = 'SiteLink';

export default SiteLink;
