import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { TwoColumnImagesRowFragment } from '../../../gatsby-graphql';

const TwoColumnImagesRow: React.FunctionComponent<TwoColumnImagesRowFragment> = ({
  firstColumnTitle,
  secondColumnTitle,
  firstColumnImage,
  secondColumnImage,
  firstColumnSubtitle,
  secondColumnSubtitle,
}) => {
  return (
    <Container>
      <Column>
        <h4>
          {firstColumnTitle}
          <span>{firstColumnSubtitle && firstColumnSubtitle}</span>
        </h4>
        <ImageContainer>
          <GatsbyImage
            alt={firstColumnImage?.description as string}
            image={firstColumnImage?.gatsbyImageData}
          />
        </ImageContainer>
      </Column>
      <Column>
        <h4>
          {secondColumnTitle}
          <span>{secondColumnSubtitle && secondColumnSubtitle}</span>
        </h4>
        <ImageContainer>
          <GatsbyImage
            alt={secondColumnImage?.description as string}
            image={secondColumnImage?.gatsbyImageData}
          />
        </ImageContainer>
      </Column>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  flex-direction: column;
  ${(props) => props.theme.mq.desktop} {
    flex-direction: row;
    gap: 20px;
  }

  h4 {
    margin-block-end: 20px;
    margin-block-start: 0;
  }
  span {
    display: block;
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;
  ${(props) => props.theme.mq.desktop} {
    width: auto;
  }
`;

const ImageContainer = styled.div`
  flex-basis: 46%;
  text-align: left;
  & > .gatsby-image-wrapper {
    margin-top: 0 !important;
  }
`;

export default TwoColumnImagesRow;
