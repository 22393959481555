import React from 'react';
import styled from 'styled-components';
import ButtonLink from './ButtonLink';
import { ILinkProps } from './ButtonLink';

type LinkGroupTheme = 'light' | 'dark';

export interface ILinkGroupProps {
  titleTop: string;
  titleBottom: string;
  background: LinkGroupTheme;
  links: ILinkProps[];
}

const ButtonLinkGroup: React.FC<ILinkGroupProps> = ({
  titleTop,
  titleBottom,
  background,
  links,
}) => (
  <LinkGroupContainer>
    <h4>
      {titleTop}
      <br />
      {titleBottom}
    </h4>
    <LinkGroupInner background={background}>
      {links.map((link, index) => (
        <ButtonLink key={index} {...link} />
      ))}
    </LinkGroupInner>
  </LinkGroupContainer>
);

const LinkGroupContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  h4 {
    margin-block-end: 20px;
  }
`;

const LinkGroupInner = styled.div<{ background: LinkGroupTheme }>`
  inline-size: 200px;
  & > div {
    padding-block-end: 10px;
  }
  ${(props) =>
    props.background === 'dark' &&
    `background-color: ${props.theme.colors.fiord};
     padding-inline: 5px;
     padding-block-start: 5px;
     margin-block-start: 10px;
     color: ${props.theme.colors.white};
     & > div:last-child {
      padding-block-end: 0px;
     }
     a, a:hover{    
        color:${props.theme.colors.white};
      }
     p{
        color:${props.theme.colors.white};
      }
     path, path:hover{
        fill: ${props.theme.colors.white}!important;
      }
    `}
`;

export default ButtonLinkGroup;
