import React from 'react';
import ColorsGrid, { IColors } from './ColorsGrid';

const colors: IColors[] = [
  {
    name: 'Success',
    codes: {
      HEX: '#29941A',
    },
  },
  {
    name: 'Error',
    codes: {
      HEX: '#C50000',
    },
  },
  {
    name: 'Alert',
    codes: {
      HEX: '#F79737',
    },
  },
];

const UIColors: React.FC = () => <ColorsGrid colors={colors} />;

export default UIColors;
