import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { animated, useSpring } from 'react-spring';
import { GroupLinkFragment, HeaderFragment } from '../../../gatsby-graphql';
import useHandleScroll from '../../hooks/useHandleScroll';
import { BaseContainer } from '../BaseComponents';
import MobileHeaderGroupLink from './MobileHeaderGroupLink';

interface IMobileNavProps {
  navigation: HeaderFragment['navigation'];
  mobileCloseMenuAssistiveLabel: HeaderFragment['mobileCloseMenuAssistiveLabel'];
  mobileOpenMenuAssistiveLabel: HeaderFragment['mobileOpenMenuAssistiveLabel'];
  mobileExpandedMenuCloseLabel: HeaderFragment['mobileExpandedMenuCloseLabel'];
}

const MobileNav: React.FC<IMobileNavProps> = ({
  navigation,
  mobileExpandedMenuCloseLabel,
  mobileOpenMenuAssistiveLabel,
  mobileCloseMenuAssistiveLabel,
}) => {
  const { disableWindowScroll, enableWindowScroll } = useHandleScroll();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const menuSpring = useSpring({
    transform: isDrawerOpen ? 'translateX(0)' : 'translateX(100%)',
  });
  useEffect(() => {
    if (isDrawerOpen) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      disableWindowScroll();
    } else {
      enableWindowScroll();
    }
  }, [isDrawerOpen]);
  return (
    <Container>
      <button
        onClick={() => setIsDrawerOpen((prev) => !prev)}
        aria-label={String(
          isDrawerOpen
            ? mobileCloseMenuAssistiveLabel
            : mobileOpenMenuAssistiveLabel
        )}
        aria-expanded={isDrawerOpen}
      >
        <StaticImage src="../../images/img.png" width={20} alt="Menu Icon" />
      </button>
      <Drawer style={menuSpring} role="menubar" aria-hidden={!isDrawerOpen}>
        {navigation?.map((groupLink) => (
          <MobileHeaderGroupLink
            {...(groupLink as GroupLinkFragment)}
            key={groupLink?.id as string}
            drawerCloseLabel={mobileExpandedMenuCloseLabel as string}
            forceDrawerClose={isDrawerOpen}
          />
        ))}
      </Drawer>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  overflow: hidden;
  & > button {
    background-color: transparent;
    border: none;
  }
  ${(props) => props.theme.mq.desktop} {
    display: none;
  }
`;

const Drawer = styled(animated(BaseContainer))`
  background-color: ${(props) => props.theme.colors.white};
  border-top: solid 1px #d5d5d5;
  position: absolute;
  top: ${(props) => props.theme.sizes.mobileHeaderHeight}px;
  left: 0;
  width: 100%;
  height: calc(100vh - ${(props) => props.theme.sizes.mobileHeaderHeight}px);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 10;
`;

export default MobileNav;
