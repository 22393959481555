import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import fonts from './fonts';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${fonts}
  
  *{
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  *:focus {
    outline: solid 2px ${(props) => props.theme.colors.ronchi};
    outline-offset: 1px;
  }
  
  html, body {
    position: relative;
    overflow-x: hidden;
    ${(props) => props.theme.mq.desktop} {
      overflow-x: unset;
    }
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  h1 {
    font-family: ${(props) => props.theme.fonts.GTSectra};
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.paradiso};
    ${(props) => props.theme.mq.desktop} {
      font-size: 64px;
      line-height: 80px;  
    }
  }
  
  h2 {
    font-family: ${(props) => props.theme.fonts.GTSectra};
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.fiord};
    ${(props) => props.theme.mq.desktop} {
      font-size: 48px;
      line-height: 1.13;
    }
  }
  
  h3 {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  
  p {
    color: ${(props) => props.theme.colors.limedSpruce};
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    white-space: pre-line;
    a {
      color: inherit;
    }
  }
`;
export default GlobalStyle;
