import React from 'react';
import styled from 'styled-components';

export interface IButtonProps {
  label: string;
  variant: 'dark' | 'light';
  outline: boolean;
  isDisabled?: boolean;
}
const Button: React.FC<IButtonProps> = ({
  label,
  variant,
  outline,
  isDisabled,
}) => (
  <ButtonElement disabled={isDisabled} variant={variant} outline={outline}>
    {label}
  </ButtonElement>
);

const ButtonElement = styled.button<{
  variant: 'dark' | 'light';
  outline: boolean;
  disabled: boolean | undefined;
}>`
  margin-block-end: 20px;
  padding: 10px 20px;
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 12px;
  font-weight: 700;
  color: white;
  letter-spacing: 0.2em;
  line-height: 1.83;
  text-transform: uppercase;
  border: none;
  ${(props) =>
    props.variant === 'dark' &&
    `background-color: ${props.theme.colors.fiord};`}
  ${(props) =>
    props.variant === 'light' &&
    !props.outline &&
    `background-color: ${props.theme.colors.fireDark};`}
  ${(props) =>
    props.variant === 'light' &&
    props.outline &&
    `background-color: ${props.theme.colors.white};
     color: ${props.theme.colors.fireDark};
     border: 2px solid ${props.theme.colors.fireDark};`}
  ${(props) =>
    props.variant === 'light' &&
    props.disabled &&
    `background-color: ${props.theme.colors.alto};
     color: ${props.theme.colors.white}`}
  ${(props) =>
    props.variant === 'dark' &&
    (props.outline || props.disabled) &&
    `outline: 1px solid ${props.theme.colors.white};
     outline-offset: -4px;`}
`;

export default Button;
