import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../../icons';

interface IAlertProps {
  items: IAlertItems[];
}

export interface IAlertItems {
  id: string;
  type: string;
  color: string;
  title: string;
  content: string;
}

const AlertElement: React.FC<IAlertItems> = ({
  id,
  color,
  type,
  title,
  content,
}) => (
  <AlertContainer key={id}>
    <h4>{type}</h4>
    <AlertCard color={color}>
      <AlertTitle>
        <h3>{title}</h3>
        {/*@ts-expect-error: we are auto-generating React components from svg files*/}
        <CloseIcon />
      </AlertTitle>
      <p>{content}</p>
    </AlertCard>
  </AlertContainer>
);

const AlertModule: React.FC<IAlertProps> = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <AlertElement key={item.id} {...item} />
      ))}
    </>
  );
};

const AlertContainer = styled.div`
  h4 {
    margin-block-start: 40px !important;
    margin-block-end: 40px;
  }
`;

const AlertCard = styled.div`
  background-color: ${(props) => props.theme.colors[props.color]};
  padding: 30px;
  p {
    color: ${(props) => props.theme.colors.white};
    margin-block: 0;
  }
  h3 {
    margin-block-start: 0 !important;
    margin-block-end: 20px;
    color: ${(props) => props.theme.colors.white}!important;
  }
`;

const AlertTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AlertModule;
