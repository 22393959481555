import { useEffect, useState } from 'react';
import { _window } from '../utils';

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(_window.innerWidth <= 1024);
  const handleResize = () => {
    setIsMobile(_window.innerWidth <= 1024);
  };
  useEffect(() => {
    window.addEventListener('resize', () => handleResize());
    return () => window.removeEventListener('resize', () => handleResize());
  }, []);
  return isMobile;
};

export default useIsMobile;
