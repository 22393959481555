import React from 'react';
import styled from 'styled-components';
import { GroupLinkFragment, HeaderFragment } from '../../../gatsby-graphql';
import DesktopHeaderGroupLink from './DesktopHeaderGroupLink';

interface IDesktopNavProps {
  navigation: HeaderFragment['navigation'];
  path: string;
}

const DesktopNav: React.FC<IDesktopNavProps> = ({ navigation, path }) => (
  <Container role="menubar">
    {navigation?.map((groupLink) => (
      <DesktopHeaderGroupLink
        key={groupLink?.id as string}
        data={groupLink as GroupLinkFragment}
        path={path}
      />
    ))}
  </Container>
);

const Container = styled.div`
  display: none;
  ${(props) => props.theme.mq.desktop} {
    display: block;
  }
`;

export default DesktopNav;
