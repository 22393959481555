import React from 'react';
import ColorsGrid, { IColors } from './ColorsGrid';

const colors: IColors[] = [
  {
    name: 'Slate Dark',
    codes: {
      HEX: '#323F4A',
    },
  },
  {
    name: 'Slate Light',
    codes: {
      HEX: '#8B99A6',
    },
  },
  {
    name: 'Yellow Muted',
    codes: {
      HEX: '#F7D18F',
    },
  },
  {
    name: 'Teal Light',
    codes: {
      HEX: '#E1F6F4',
    },
  },
];

const SecondaryWebColors: React.FC = () => <ColorsGrid colors={colors} />;

export default SecondaryWebColors;
