import React from 'react';
import styled from 'styled-components';
import {
  ContentRowFragment,
  OneColumnContentListingFragment,
} from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import ContentRow from '../ContentRow/ContentRow';

interface IOneColumnContentListingProps
  extends OneColumnContentListingFragment {
  internalPageNavigation?: boolean;
}

const OneColumnContentListing = React.forwardRef<
  any,
  IOneColumnContentListingProps
>(({ header, contentRows, internalPageNavigation, listStyle }, ref) => (
  <Container ref={ref}>
    <Content internalPageNavigation={internalPageNavigation}>
      <h2>{header?.header}</h2>
      {contentRows?.map((row) => (
        <StyledContentRow
          key={row?.id}
          isBulletList={listStyle === 'bullets'}
          {...(row as ContentRowFragment)}
        />
      ))}
    </Content>
  </Container>
));

const Container = styled.div`
  padding-top: 50px;
  &:nth-of-type(3n + 2) {
    background-color: ${(props) => props.theme.colors.porcelain};
  }
  &:nth-of-type(3n + 3) {
    background-color: ${(props) => props.theme.colors.paradiso};
    h2,
    h3,
    p {
      color: ${(props) => props.theme.colors.white};
    }
    div > div {
      border-bottom-color: rgba(255, 255, 255, 0.3);
    }
  }
  ${(props) => props.theme.mq.desktop} {
    padding-top: 100px;
  }
`;

const Content = styled(BaseContainer)<{ internalPageNavigation?: boolean }>`
  padding-bottom: 50px;
  h2 {
    margin: 0 0 30px;
  }
  ${(props) => props.theme.mq.desktop} {
    padding-bottom: 100px;
    h2 {
      margin: 0 0 60px;
    }
    ${(props) =>
      props.internalPageNavigation
        ? props.theme.sizes.sideNavigation.contentOffset
        : ''};
  }
`;

const StyledContentRow = styled(ContentRow)`
  ${(props) => props.isBulletList && `display:list-item;`}
  ${(props) => props.isBulletList && `margin-inline-start:25px;`}
  margin-bottom: 20px;
  padding-bottom: ${(props) => (props.isBulletList ? '0px' : '30px')};
  border-bottom: ${(props) =>
    !props.isBulletList && `1px solid ${props.theme.colors.alto}`};

  ${(props) => props.theme.mq.desktop} {
    &:not(:last-of-type) {
      padding-bottom: ${(props) => (props.isBulletList ? '0px' : '20px')};
      margin-bottom: ${(props) => (props.isBulletList ? '20px' : '40px')};
    }
  }

  h3 {
    color: ${(props) => props.theme.colors.limedSpruce};
    margin: 0 0 10px;
  }
`;

OneColumnContentListing.displayName = 'OneColumnContentListing';

export default OneColumnContentListing;
