import React from 'react';
import styled from 'styled-components';
import { InternalPageNavigationFragment } from '../../../gatsby-graphql';
import useIsMobile from '../../hooks/useIsMobile';
import InternalPageNavigationComponents from './InternalPageNavigationComponents';
import SideNavigation, { SideNavigationItems } from './SideNavigation';
import SideNavigationContext from './context/SideNavigationContext';
import useSideNavigation from './hooks/useSideNavigation';
import MobileNavigation from './MobileNavigation';

const InternalPageNavigation: React.FC<InternalPageNavigationFragment> = ({
  components,
}) => {
  const sideNavigation = useSideNavigation();
  const isMobile = useIsMobile();
  const navigationItems: SideNavigationItems = components?.map((component) => {
    const navigationHeader = String(
      component?.sideNavigationTitle || component?.header?.header
    );
    return {
      id: component?.id,
      header: navigationHeader,
      anchor: navigationHeader.toLowerCase().replace(/\s/g, '-'),
    };
  });

  return (
    <SideNavigationContext.Provider value={sideNavigation}>
      <ContentContainer ref={sideNavigation.parentRef}>
        {isMobile ? (
          <MobileNavigation items={navigationItems} />
        ) : (
          <SideNavigation items={navigationItems} />
        )}
        <InternalPageNavigationComponents components={components} />
      </ContentContainer>
    </SideNavigationContext.Provider>
  );
};

const ContentContainer = styled.div`
  position: relative;
`;

export default InternalPageNavigation;
