import React from 'react';
import ColorsGrid, { IColors } from './ColorsGrid';

const colors: IColors[] = [
  {
    name: 'Slate',
    codes: {
      HEX: '#435363',
    },
  },
  {
    name: 'Fire Dark',
    codes: {
      HEX: '#D93600',
    },
  },
  {
    name: 'Teal',
    codes: {
      HEX: '#297A76',
    },
  },
];

const PrimaryWebColors: React.FC = () => <ColorsGrid colors={colors} />;

export default PrimaryWebColors;
