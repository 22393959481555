import React from 'react';
import styled from 'styled-components';
import TextLink, { ILinkProps } from './TextLink';

type LinkGroupTheme = 'light' | 'dark';

export interface ILinkGroupProps {
  title: string;
  type: LinkGroupTheme;
  links: ILinkProps[];
}

const TextLinkGroup: React.FC<ILinkGroupProps> = ({ title, type, links }) => (
  <LinkGroupContainer>
    <h4>{title}</h4>
    <LinkGroupInner type={type}>
      {links.map((link, index) => (
        <TextLink key={index} {...link} />
      ))}
    </LinkGroupInner>
  </LinkGroupContainer>
);

const LinkGroupContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const LinkGroupInner = styled.div<{ type: LinkGroupTheme }>`
  inline-size: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) =>
    props.type === 'dark' &&
    `background-color: ${props.theme.colors.fiord};
     color:${props.theme.colors.white};
     block-size: 90px;
     margin-block-start: 10px;
     padding-top: 14px;
     padding-inline:10px;
     a{    
       text-decoration-color: ${props.theme.colors.yellowMuted};
     }
     p{
       color:${props.theme.colors.white};
     }
    `}
`;

export default TextLinkGroup;
