import React from 'react';
import styled from 'styled-components';
import { ILinkProps } from './ButtonLink';
import ButtonLinkGroup from './ButtonLinkGroup';
import { ILinkGroupProps } from './ButtonLinkGroup';

const rightLightButtonLinks: ILinkProps[] = [
  {
    linkText: 'Link with Icon',
    label: 'Icon Right - Fire Dark - Hover over it',
    iconDirection: 'right',
  },
];
const leftLightButtonLinks: ILinkProps[] = [
  {
    linkText: 'Link with Icon',
    label: 'Icon Left - Fire Dark - Hover over it',
    iconDirection: 'left',
  },
];
const rightDarkButtonLinks: ILinkProps[] = [
  {
    linkText: 'Link with Icon',
    label: 'Icon Right - White - Hover over it',
    iconDirection: 'right',
  },
];
const leftDarkButtonLinks: ILinkProps[] = [
  {
    linkText: 'Link with Icon',
    label: 'Icon Left - White - Hover over it',
    iconDirection: 'left',
  },
];

const rightButtonLinkGroup: ILinkGroupProps = {
  titleTop: 'Button Links',
  titleBottom: 'with Right Icons - On Light',
  background: 'light',
  links: rightLightButtonLinks,
};
const leftButtonLinkGroup: ILinkGroupProps = {
  titleTop: 'Button Links',
  titleBottom: 'with Left Icons - On Light',
  background: 'light',
  links: leftLightButtonLinks,
};
const rightDarkButtonLinkGroup: ILinkGroupProps = {
  titleTop: 'Button Links',
  titleBottom: 'with Right Icons - On Dark',
  background: 'dark',
  links: rightDarkButtonLinks,
};
const leftDarkButtonLinkGroup: ILinkGroupProps = {
  titleTop: 'Button Links',
  titleBottom: 'with Left Icons - On Dark',
  background: 'dark',
  links: leftDarkButtonLinks,
};

const ButtonLinks: React.FC = () => (
  <>
    <Container>
      <ButtonLinkGroup {...rightButtonLinkGroup} />
      <ButtonLinkGroup {...leftButtonLinkGroup} />
    </Container>
    <Container>
      <ButtonLinkGroup {...rightDarkButtonLinkGroup} />
      <ButtonLinkGroup {...leftDarkButtonLinkGroup} />
    </Container>
  </>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.mq.desktop} {
    flex-direction: row;
  }
`;

export default ButtonLinks;
