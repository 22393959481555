import React from 'react';
import styled from 'styled-components';
import { BaseCard } from '../BaseComponents';
import AccordionItem from './AccordionItem';

interface IAccordionTabsItems {
  id: string;
  label: string;
  content: string;
}

interface IAccordion {
  items: IAccordionTabsItems[];
}

const Accordion: React.FC<IAccordion> = ({ items }) => (
  <Container>
    {items?.map((item) => {
      return <AccordionItem key={item?.id} {...item}></AccordionItem>;
    })}
  </Container>
);

const Container = styled(BaseCard)`
  padding: 30px 40px;
  box-shadow: none;
  margin-top: 40px;
  background-color: ${(props) => props.theme.colors.fiord};
  ${(props) => props.theme.mq.desktop} {
    padding: 38px 60px;
  }
  & > div + div {
    border-block-start: 1px solid white;
    padding-block-start: 10px;
  }
  & > div:first-child p {
    margin-block-end: 30px;
  }
`;

export default Accordion;
