import styled from 'styled-components';
import theme from '../styles/theme';

export const BaseContainer = styled.div`
  margin: 0 auto;
  padding-left: 22px;
  padding-right: 22px;
  ${(props) => props.theme.mq.desktop} {
    max-width: ${(props) => props.theme.sizes.containerMaxWidth}px;
  }
  @media screen and (min-width: ${theme.sizes.containerMaxWidth}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BaseCard = styled.div`
  box-shadow: ${(props) => props.theme.generics.boxShadow};
  background-color: ${(props) => props.theme.colors.white};
`;

interface IBaseBorderCardProps {
  readonly borderColor: string;
}

export const BaseBorderCard = styled(BaseCard)<IBaseBorderCardProps>`
  padding: 20px;
  display: flex;
  & > div {
    position: relative;
    border: solid 1px ${(props) => props.borderColor};
    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      height: 1px;
      width: 100%;
      background: ${(props) => props.borderColor};
    }
    &:after {
      top: 7px;
    }
    &:before {
      bottom: 7px;
    }
`;
