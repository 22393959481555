import React, { useContext, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { BaseBorderCard, BaseContainer } from '../BaseComponents';
import SideNavigationContext from './context/SideNavigationContext';

export type SideNavigationItems =
  | { header?: string; id?: string; anchor?: string }[]
  | undefined;

interface ISideNavigationProps {
  items: SideNavigationItems;
}

const SideNavigation: React.FC<ISideNavigationProps> = ({ items }) => {
  const { activeNavigationItem, scrollToItem } = useContext(
    SideNavigationContext
  );
  const placeholderElement = useRef<HTMLDivElement>(null);
  const placeholderElementOffsetTop = useRef<number | null>(null);
  const { colors } = useTheme();

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event.preventDefault();
    scrollToItem(index);
  };

  useEffect(() => {
    if (placeholderElement.current && !placeholderElementOffsetTop.current) {
      const { offsetTop } = placeholderElement.current;
      placeholderElementOffsetTop.current = offsetTop;
    }
  });

  return (
    <NavigationPlaceholder ref={placeholderElement}>
      <SideNavigationCard borderColor={colors.alto}>
        <div>
          {items?.map((item, index) => (
            <SideNavigationLink
              key={item?.id}
              className={index === activeNavigationItem ? 'active' : ''}
            >
              <a
                href={`#${item?.anchor}`}
                onClick={(e) => handleLinkClick(e, index)}
              >
                {item?.header}
              </a>
            </SideNavigationLink>
          ))}
        </div>
      </SideNavigationCard>
    </NavigationPlaceholder>
  );
};

const NavigationPlaceholder = styled(BaseContainer)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const SideNavigationCard = styled(BaseBorderCard)`
  position: sticky;
  top: 50px;
  width: 330px;
  pointer-events: all;
  & > div {
    padding: 30px 0;
    width: 100%;
  }
`;

const SideNavigationLink = styled.div`
  padding: 0 32px;
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
  a {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.fiord};
    text-decoration: none;
  }
  &.active {
    a {
      font-weight: bold;
      color: ${(props) => props.theme.colors.paradiso};
      &:before {
        content: '';
        background: ${(props) => props.theme.colors.paradiso};
        position: absolute;
        top: 0;
        height: 100%;
        left: -1px;
        width: 4px;
        bottom: 0;
      }
    }
  }
`;

export default SideNavigation;
