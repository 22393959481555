import React from 'react';
import TintsGrid from './TintsGrid';

const firstBlockColors = ['#323F4A', '#FF4C00', '#68C8C7', '#A1D5B2'];
const secondBlockColors = ['#6D8091', '#32404B', '#F89738', '#EBC95A'];

const Tints: React.FC = () => (
  <TintsGrid firstBlock={firstBlockColors} secondBlock={secondBlockColors} />
);

export default Tints;
