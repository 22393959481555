import React from 'react';
import styled, { useTheme } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { TwoColumnRightImageRowFragment } from '../../../gatsby-graphql';

const TwoColumnRightImageRow: React.FunctionComponent<TwoColumnRightImageRowFragment> = ({
  title,
  underline,
  image,
  description,
}) => {
  const { colors } = useTheme();
  const underlineColor = {
    green: colors.paradiso,
    red: colors.guardsmanRed,
  };
  return (
    <Container>
      <TextContainer color={underlineColor[underline as string]}>
        <span>{title?.title}</span>
        {description && (
          <div>
            {renderRichText(
              description as RenderRichTextData<ContentfulRichTextGatsbyReference>
            )}
          </div>
        )}
      </TextContainer>
      <ImageContainer>
        <GatsbyImage
          alt={image?.description as string}
          image={image?.gatsbyImageData}
          objectFit="contain"
        />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-block-end: 60px;
  flex-direction: column;
  ${(props) => props.theme.mq.desktop} {
    flex-direction: row;
    border-bottom: 1px solid ${(props) => props.theme.colors.alto};
    padding-block-end: 2em;
    margin-block-end: 45px;
  }
`;

const TextContainer = styled.div<{ color: string }>`
  flex: 1;
  width: 100%;
  ${(props) => props.theme.mq.desktop} {
    width: auto;
    padding-right: 55px;
  }
  span {
    padding: 0 0 10px 0;
    margin: 0 0 16px;
    ${(props) => props.theme.mq.desktop} {
      padding: 0 0 6px 21px;
    }
    display: block;
    border-bottom: 1px dashed ${(props) => props.color};
    font-family: ${(props) => props.theme.fonts.gotham};
    color: ${(props) => props.theme.colors.limedSpruce};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2em;
    position: relative;
    text-transform: uppercase;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 11px;
      height: 11px;
      border-radius: 100%;
      background-color: ${(props) => props.color};
      display: none;
      ${(props) => props.theme.mq.desktop} {
        display: block;
      }
    }
  }
  & > div {
    margin-bottom: 20px;
    ${(props) => props.theme.mq.desktop} {
      margin: 0;
    }
  }
`;

const ImageContainer = styled.div`
  flex-basis: 46%;
  text-align: center;
  & > .gatsby-image-wrapper {
    margin-top: 0;
  }
`;

export default TwoColumnRightImageRow;
