import { useRef, useState } from 'react';
import { ISideNavigationContext } from '../context/SideNavigationContext';

const useSideNavigation = (): ISideNavigationContext => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [activeNavigationItem, setActiveNavigationItem] = useState<number>(0);
  const [indexToScroll, setIndexToScroll] = useState<number | null>(null);

  const scrollToItem = (index: number) => {
    setIndexToScroll(index);
    setActiveNavigationItem(index);
  };

  return {
    activeNavigationItem,
    setActiveNavigationItem,
    scrollToItem,
    indexToScroll,
    parentRef,
  };
};

export default useSideNavigation;
