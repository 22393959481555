import React from 'react';
import styled from 'styled-components';

export interface ICheckboxProps {
  id: string;
  label: string;
  isDisabled?: boolean;
  name: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({ id, label, isDisabled }) => (
  <CheckboxContainer isDisabled={isDisabled}>
    <input
      type="checkbox"
      id={`checkbox-${id}`}
      value="checkboxValue"
      disabled={isDisabled}
    />
    <CheckboxLabel for={`checkbox-${id}`} isDisabled={isDisabled}>
      {label}
    </CheckboxLabel>
  </CheckboxContainer>
);

const CheckboxContainer = styled.label<{ isDisabled: boolean | undefined }>`
  display: flex;
  align-items: center;

  input {
    inset-inline-start: -9999px;
    position: absolute;
  }

  input[type='checkbox'] + span:before {
    background: ${(props) => props.theme.colors.white};
    border: ${(props) =>
      props.isDisabled
        ? `0.0625rem solid ${props.theme.colors.slateLight};`
        : `0.0625rem solid ${props.theme.colors.fiord};`}
    content: '';
    block-size: 1.25rem;
    inset-inline-start: 0;
    position: absolute;
    inset-block-start: 0;
    inline-size: 1.25rem;
  }

  input[type='checkbox']:checked + span:after {
    background: ${(props) => props.theme.colors.guardsmanRed};
    content: '';
    block-size: 1rem;
    inset-inline-start: 0.1875rem;
    position: absolute;
    inset-block-start: 0.1875rem;
    inline-size: 1rem;
  }
`;

const CheckboxLabel = styled.span<{ isDisabled: boolean | undefined }>`
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 14px;
  ${(props) => props.isDisabled && `color: ${props.theme.colors.slateLight};`}
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25rem;
  padding-left: 2.1875rem;
  position: relative;
`;

export default Checkbox;
