import React from 'react';
import ReactComponents from './Components';

interface IGenericContentEmbeddedEntryProps {
  __typename: string;
  props: Record<string, unknown>;
}

const GenericContentEmbeddedEntry: React.FunctionComponent<IGenericContentEmbeddedEntryProps> = ({
  __typename,
  props,
}) => {
  const ComponentName = __typename.replace('Contentful', '');
  if (!ComponentName || !ReactComponents[ComponentName]) return null;
  const Component = ReactComponents[ComponentName];
  return <Component {...props} />;
};

export default GenericContentEmbeddedEntry;
