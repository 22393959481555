import React, { useState, useRef } from 'react';
import styled from 'styled-components';

export interface ITabsItems {
  id: string;
  label: string;
  title: string;
  description: string;
}

interface ITabsProps {
  items: ITabsItems[];
}

const TabsModule: React.FC<ITabsProps> = ({ items }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const refs = useRef<HTMLButtonElement>({});
  const handleTabSelection = (index: number) => {
    const tab = refs.current && refs.current[index];
    tab && tab.focus();
  };

  const handleKeyDown = (event) => {
    const tabsSize = items.length;
    const tabRight = () => {
      handleTabSelection((currentTab + 1) % tabsSize);
    };
    const tabLeft = () => {
      handleTabSelection((currentTab - 1 + tabsSize) % tabsSize);
    };
    const firstTab = () => {
      handleTabSelection(0);
    };
    const lastTab = () => {
      handleTabSelection(tabsSize - 1);
    };

    const selectTab = () => {
      setSelectedTab(currentTab);
    };

    const keysMap = {
      ArrowRight: tabRight,
      ArrowLeft: tabLeft,
      Home: firstTab,
      End: lastTab,
      Enter: selectTab,
    };

    if (event.keyCode === 32) {
      selectTab();
    }

    const keyPress = keysMap[event.key];

    if (keyPress) {
      event.preventDefault();
      keyPress();
    }
  };

  return (
    <Container>
      <TabsContainer role="tablist" aria-orientation="horizontal">
        {items?.map((tab, index) => {
          return (
            <Tab
              key={tab?.id}
              ref={(current) => {
                if (refs.current) return (refs.current[index] = current);
              }}
              onFocus={() => {
                setCurrentTab(index);
              }}
              onClick={() => setSelectedTab(index)}
              onKeyDown={handleKeyDown}
              aria-controls={`panel-id-${index}`}
              aria-selected={selectedTab === index}
              role="tab"
              tabIndex={currentTab === index ? '0' : '-1'}
            >
              {tab.label}
            </Tab>
          );
        })}
        <HiddenTab tabIndex="-1">{'.'}</HiddenTab>
      </TabsContainer>
      {items?.map((tab, index) => {
        return (
          selectedTab === index && (
            <TabsPanel id={`panel-id-${index}`} role="tabpanel" tabIndex={0}>
              <h3>{tab.title}</h3>
              <p>{tab.description}</p>
            </TabsPanel>
          )
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;
`;

const TabsContainer = styled.div`
  display: flex;
  padding-block-start: 30px;
`;

const Tab = styled.button`
  padding: 30px;
  ${(props) => props.theme.mq.tablet} {
    padding: 30px 75px;
  }
  border-top: ${(props) =>
    props['aria-selected'] ? `2px solid ${props.theme.colors.paradiso}` : '0'};

  ${(props) =>
    props['aria-selected']
      ? `
      border-right: 1px solid ${props.theme.colors.alto};
      border-bottom: 0;
      border-left: 1px solid ${props.theme.colors.alto};
      `
      : `
      border-bottom: 1px solid ${props.theme.colors.alto};
      border-right: 0;
      border-left: 0;
      `}

  &:first-of-type {
    border-left: 0;
  }

  display: inline-block;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

const HiddenTab = styled(Tab)`
  color: white;
  flex-grow: 2;
  border-block-end: 1px solid ${(props) => props.theme.colors.alto};
  border-block-start: none;
  pointer-events: none;
`;

const TabsPanel = styled.section`
  margin: 20px;
  ${(props) => props.theme.mq.desktop} {
    margin: 70px 123px 45px 124px;
  }
  h3 {
    margin: 0 !important;
  }
`;

export default TabsModule;
