import React from 'react';
import styled from 'styled-components';

export interface ITintsGridProps {
  firstBlock: string[];
  secondBlock: string[];
}

const TintsGrid: React.FC<ITintsGridProps> = ({ firstBlock, secondBlock }) => {
  const variations = [1, 0.8, 0.6, 0.4, 0.2];
  return (
    <TintsContainer>
      {[firstBlock, secondBlock].map((color) => (
        <>
          <Percentages>
            {variations.map((variation, index) => (
              <p key={index}>{`${variation * 100}%`}</p>
            ))}
          </Percentages>
          <TintsInner>
            {variations.map((variation, index) => (
              <TintsRow key={index}>
                {color.map((color, index) => (
                  <ColorBox
                    key={index}
                    color={color}
                    variation={variation}
                  ></ColorBox>
                ))}
              </TintsRow>
            ))}
          </TintsInner>
        </>
      ))}
    </TintsContainer>
  );
};

const TintsContainer = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 8.8fr;
  margin-block-start: 40px;

  ${(props) => props.theme.mq.tablet} {
    & > div:nth-of-type(3) {
      display: none;
    }
    grid-template-columns: 6fr 47fr 47fr;
  }
`;

const TintsInner = styled.div`
  &:last-of-type {
    ${(props) => props.theme.mq.tablet} {
      margin-inline-start: 10px;
    }
  }
`;

const TintsRow = styled.div`
  inline-size: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ColorBox = styled.div<{ color: string; variation: number }>`
  block-size: 50px;
  background-color: ${(props) => props.color};
  opacity: ${(props) => props.variation};
  flex-grow: 1;
`;

const Percentages = styled.div`
  margin-top: 16px;

  p {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 10px;
    block-size: 50px;
    margin: 0;

    ${(props) => props.theme.mq.tablet} {
      inline-size: 40px;
    }
  }
`;
export default TintsGrid;
