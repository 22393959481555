import React from 'react';
import { PageComponentsFragment } from '../../../gatsby-graphql';
import ReactComponents from './Components';
interface IPageContentProps {
  components?: PageComponentsFragment['components'] | null;
}

const PageComponents: React.FC<IPageContentProps> = ({ components }) => (
  <>
    {components?.map((component, index) => {
      const ComponentName = component?.__typename.replace('Contentful', '');
      if (!ComponentName || !ReactComponents[ComponentName]) return null;
      const Component = ReactComponents[ComponentName];
      return (
        <Component key={`${component?.__typename}-${index}`} {...component} />
      );
    })}
  </>
);

export default PageComponents;
