import React from 'react';
import styled from 'styled-components';
import { GroupLinkFragment, LinkFragment } from '../../../gatsby-graphql';
import SiteLink from '../SitelLink/SiteLink';
import { chunkArray } from '../../utils';

const FooterGroupLink: React.FC<GroupLinkFragment> = ({
  headerLink,
  links,
}) => {
  const columns = chunkArray(links as [], 4);
  return (
    <Container columns={columns.length} className={`columns-${columns.length}`}>
      <HeaderLink {...(headerLink as LinkFragment)} />
      <ColumnsContainer>
        {columns.map((column) => (
          <LinksContainer key={column[0].id}>
            {column.map((link) => (
              <div key={link?.id}>
                <ListLink {...(link as LinkFragment)} />
              </div>
            ))}
          </LinksContainer>
        ))}
      </ColumnsContainer>
    </Container>
  );
};

const Container = styled.div<{ columns: number }>`
  flex-basis: ${(props) => (props.columns >= 2 ? '100%' : '50%')};
  ${(props) => props.theme.mq.tablet} {
    flex-basis: 100%;
    &.columns-1 {
      flex-basis: 33.33%;
    }
    &.columns-2 {
      flex-basis: 66.66%;
    }
  }
  ${(props) => props.theme.mq.desktop} {
    &,
    &.columns-1,
    &.columns-2 {
      flex-basis: auto;
    }
    &:not(:first-of-type) {
      margin-left: 80px;
    }
  }
`;

const HeaderLink = styled(SiteLink)`
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.limedSpruce};
  text-decoration: none;
  text-transform: uppercase;
  a& {
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${(props) => props.theme.colors.paradiso};
    }
  }
`;

const ColumnsContainer = styled.div`
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  ${(props) => props.theme.mq.tablet} {
    flex-wrap: nowrap;
    justify-content: space-between;
    & > div {
      flex: 1;
    }
  }
  ${(props) => props.theme.mq.desktop} {
    justify-content: flex-start;
    & > div {
      flex: initial;
    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 50%;
  ${(props) => props.theme.mq.desktop} {
    &:not(:last-of-type) {
      margin-right: 80px;
    }
  }
`;

const ListLink = styled(SiteLink)`
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.fiord};
  text-decoration: none;
  margin-bottom: 25px;
  display: inline-block;
  a& {
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${(props) => props.theme.colors.paradiso};
    }
  }
`;

export default FooterGroupLink;
