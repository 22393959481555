import { graphql, useStaticQuery } from 'gatsby';
import { SiteMetadaQuery } from '../../../gatsby-graphql';

const useSiteMetadata = () => {
  const data = useStaticQuery<SiteMetadaQuery>(graphql`
    query SiteMetada {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return data?.site?.siteMetadata;
};

export default useSiteMetadata;
