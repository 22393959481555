import React from 'react';
import ColorsGrid, { IColors } from './ColorsGrid';

const colors: IColors[] = [
  {
    name: 'Mint',
    codes: {
      PMS: '344 C',
      CMYK: '38/0/38/0',
      RGB: '160/216/179',
      HEX: '#A0D8B3',
    },
  },
  {
    name: 'Slate LT',
    codes: {
      PMS: '7543 C',
      CMYK: '48/33/27/1',
      RGB: '139/153/166',
      HEX: '#8B99A6',
    },
  },
  {
    name: 'Slate DK',
    codes: {
      PMS: '7546 C',
      CMYK: '79/64/52/42',
      RGB: '50/63/74',
      HEX: '#323F4A',
    },
  },
  {
    name: 'Yellow',
    codes: {
      PMS: '128 C',
      CMYK: '8/21/65/0',
      RGB: '235/201/89',
      HEX: '#EBC959',
    },
  },
  {
    name: 'Orange',
    codes: {
      PMS: '1375 C',
      CMYK: '0/48/88/0',
      RGB: '247/151/55',
      HEX: '#F79737',
    },
  },
];

const SecondaryBrandColors: React.FC = () => <ColorsGrid colors={colors} />;

export default SecondaryBrandColors;
