import React from 'react';
import styled from 'styled-components';

export interface IRadioButtonProps {
  id: string;
  label: string;
  name: string;
  isDisabled?: boolean;
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  id,
  label,
  isDisabled,
}) => (
  <RadioButtonContainer isDisabled={isDisabled}>
    <input
      type="radio"
      id={`radio-${id}`}
      value="radioValue"
      name="radioName"
      disabled={isDisabled}
    />
    <RadioLabel for={`radio-${id}`} isDisabled={isDisabled}>
      {label}
    </RadioLabel>
  </RadioButtonContainer>
);

const RadioButtonContainer = styled.label<{ isDisabled: boolean | undefined }>`
  display: flex;
  align-items: center;
  input {
    inset-inline-start: -9999px;
    position: absolute;
  }

  input[type='radio'] + span:before {
    background: ${(props) => props.theme.colors.white};
    border: ${(props) =>
      props.isDisabled
        ? `0.0625rem solid ${props.theme.colors.slateLight};`
        : `0.0625rem solid ${props.theme.colors.fiord};`}
    content: '';
    block-size: 1.25rem;
    inset-inline-start: 0;
    position: absolute;
    inset-block-start: 0;
    inline-size: 1.25rem;
    border-radius:50%;
  }

  input[type='radio']:checked + span:after {
    background: ${(props) => props.theme.colors.guardsmanRed};
    content: '';
    block-size: 1rem;
    inset-inline-start: 0.1875rem;
    position: absolute;
    inset-block-start: 0.1875rem;
    inline-size: 1rem;
    border-radius:50%;
`;

const RadioLabel = styled.span<{ isDisabled: boolean | undefined }>`
  margin-inline-start: 9px;
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 14px;
  ${(props) => props.isDisabled && `color: ${props.theme.colors.slateLight};`}
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25rem;
  padding-left: 2.1875rem;
  position: relative;
`;

export default RadioButton;
