import React from 'react';
import styled from 'styled-components';
import { AssetDownloadFragment } from '../../../gatsby-graphql';
import { DownloadIcon } from '../../icons';
import { BaseCard } from '../BaseComponents';

const AssetDownload: React.FC<AssetDownloadFragment> = ({
  assets,
  header,
  lastUpdatedLabel,
}) => (
  <DownloadContainer>
    <DownloadHeader>{header?.header}</DownloadHeader>
    {assets?.map((asset) => {
      const date = new Date(asset?.updatedAt);
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'long' });
      const year = date.getFullYear();
      return (
        <AssetContainer key={asset?.id}>
          <AssetContent>
            <span>{asset?.title}</span>
            <span>{`${lastUpdatedLabel} ${month}, ${day}, ${year}`} </span>
          </AssetContent>
          <DownloadIconContainer
            title={`Download ${asset?.title}`}
            href={asset?.file?.url as string}
            target="_blank"
            download={true}
          >
            {/*@ts-expect-error: we are auto-generating React components from svg files*/}
            <DownloadIcon />
          </DownloadIconContainer>
        </AssetContainer>
      );
    })}
  </DownloadContainer>
);

const DownloadContainer = styled(BaseCard)`
  padding: 30px 30px 40px;
  margin-top: 40px;
  ${(props) => props.theme.mq.desktop} {
    margin: 0;
    padding: 35px 40px 49px;
  }
`;

const DownloadHeader = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.limedSpruce};
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const AssetContainer = styled.div`
  padding: 20px 0 27px;
  display: flex;
  align-items: flex-start;
  &:last-of-type {
    padding-bottom: 0;
  }
  &:not(:first-of-type) {
    border-top: solid 1px #d5d5d5;
  }
`;
const AssetContent = styled.div`
  flex: 1;
  padding-right: 16px;
  span {
    display: block;
    font-family: ${(props) => props.theme.fonts.gotham};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    &:first-of-type {
      font-size: 14px;
      line-height: 26px;
      color: ${(props) => props.theme.colors.limedSpruce};
    }
    &:last-of-type {
      font-size: 10px;
      line-height: 14px;
      color: ${(props) => props.theme.colors.fiord};
    }
  }
`;

const DownloadIconContainer = styled.a`
  svg {
    display: block;
    width: 20px;
    height: 20px;
  }
`;

export default AssetDownload;
