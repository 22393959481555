import { isBuildTime } from '../utils';

const useHandleScroll = () => {
  const htmlElement = isBuildTime
    ? ({} as HTMLElement)
    : document.querySelector('html');

  const disableWindowScroll = () => {
    if (htmlElement) htmlElement.style.overflow = 'hidden';
  };

  const enableWindowScroll = () => {
    if (htmlElement) htmlElement.style.overflowY = 'scroll';
  };

  return { disableWindowScroll, enableWindowScroll };
};

export default useHandleScroll;
