import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { BaseContainer } from '../BaseComponents';
import { ChevronRightIcon } from '../../icons';
import { SideNavigationItems } from './SideNavigation';
import SideNavigationContext from './context/SideNavigationContext';

interface IMobileNavigationProps {
  items: SideNavigationItems;
}

const MobileNavigation: React.FunctionComponent<IMobileNavigationProps> = ({
  items,
}) => {
  if (!items) return null;
  const containerElement = useRef<HTMLDivElement | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isNavigationFixed, setIsNavigationFixed] = useState<boolean>(false);
  const { activeNavigationItem, scrollToItem, parentRef } = useContext(
    SideNavigationContext
  );
  const selectSpring = useSpring({
    height: isSelectOpen ? 'auto' : 0,
    overflow: isSelectOpen ? 'visible' : 'hidden',
  });

  const handleSelectTriggerClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsSelectOpen((prev) => !prev);
  };

  const handleSelectClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    event.stopPropagation();
    scrollToItem(index);
    setIsSelectOpen(false);
  };

  const handleClickOutside = () => setIsSelectOpen(false);

  const handleNavigationScroll = () => {
    if (parentRef.current) {
      const { offsetTop } = parentRef.current;
      if (window.scrollY >= offsetTop) {
        setIsNavigationFixed(true);
      } else {
        setIsNavigationFixed(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    window.addEventListener('scroll', handleNavigationScroll);
    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('scroll', handleNavigationScroll);
    };
  }, []);

  return (
    <Container ref={containerElement}>
      <SelectContainer fixed={isNavigationFixed}>
        <select aria-hidden="true">
          {items.map((item, index) => (
            <option key={item.id} value={index}>
              {item.header}
            </option>
          ))}
        </select>
        <SelectButton
          aria-haspopup="listbox"
          onClick={handleSelectTriggerClick}
        >
          <span>{items[activeNavigationItem].header}</span>
          {/*@ts-expect-error: SVG is autogenerated by Gatsby*/}
          <ChevronRightIcon />
        </SelectButton>
        <CustomSelect role="listbox" style={selectSpring}>
          {items?.map((item, index) => (
            <button key={item.id} onClick={(e) => handleSelectClick(e, index)}>
              {item.header}
            </button>
          ))}
        </CustomSelect>
      </SelectContainer>
    </Container>
  );
};

const Container = styled(BaseContainer)`
  margin-bottom: 50px;
`;

const SelectContainer = styled.div<{ fixed: boolean }>`
  position: ${(props) => (props.fixed ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  width: ${(props) => (props.fixed ? '100%' : '100%')};
  margin: 0 auto;
  z-index: 1;
  select {
    display: none;
  }
`;

const SelectButton = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border: solid 1px #d4d6d9;
  padding: 12px 20px;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 14px;
  line-height: 1.86;
  color: ${(props) => props.theme.colors.limedSpruce};
  justify-content: space-between;
  align-items: center;
  span {
    flex: 1;
    padding-right: 20px;
  }
  svg {
    width: 7px;
    height: 10px;
    transform: rotate(90deg);
  }
  &:focus {
    outline: none;
    border-color: #8b99a6;
  }
`;

const CustomSelect = styled(animated.div)`
  display: flex;
  background: ${(props) => props.theme.colors.white};
  flex-direction: column;
  border: solid 1px #d4d6d9;
  border-top: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  button {
    color: ${(props) => props.theme.colors.limedSpruce};
    border: none;
    background: transparent;
    text-align: left;
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 14px;
    line-height: 1.86;
    padding: 12px 20px;
    &:hover {
      background: #ebf8f8;
      font-weight: 700;
      color: ${(props) => props.theme.colors.paradiso};
    }
  }
`;

export default MobileNavigation;
