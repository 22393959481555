import React from 'react';
import styled from 'styled-components';
import Button, { IButtonProps } from './Button';

interface IButtonWrapperProps {
  title: string;
  description: string;
  button: IButtonProps;
}

const ButtonWrapper: React.FC<IButtonWrapperProps> = ({
  title,
  description,
  button,
}) => (
  <Container>
    <h4>{title}</h4>
    <Button {...button} />
    <p>{description}</p>
  </Container>
);

const Container = styled.div`
  grid-colum-end: span 1;
  grid-row-end: span 1;

  h4 {
    margin-block-end: 20px;
  }

  p {
    margin: 0;
    font-size: 10px;
    color: ${(props) => props.theme.colors.fiord};
  }
`;
export default ButtonWrapper;
