import React from 'react';
import styled from 'styled-components';
import {
  TwoColumnRightImageFragment,
  TwoColumnRightImageRowFragment,
} from '../../../gatsby-graphql';
import TwoColumnRightImageRow from './TwoColumnRightImageRow';

const TwoColumnRightImage: React.FunctionComponent<TwoColumnRightImageFragment> = ({
  extraMarginTop,
  rows,
}) => (
  <Container extraMarginTop={extraMarginTop}>
    {rows?.map((rowItem) => (
      <TwoColumnRightImageRow
        key={rowItem?.id}
        {...(rowItem as TwoColumnRightImageRowFragment)}
      />
    ))}
  </Container>
);

const Container = styled.div`
  border-block-start: 1px solid ${(props) => props.theme.colors.alto};
  ${(props) => props.extraMarginTop && `margin-block-start: 40px;`}
  padding-top: 40px;
`;

export default TwoColumnRightImage;
