import React from 'react';
import styled from 'styled-components';
import { BaseContainer } from '../BaseComponents';
import {
  ContentCardFragment,
  ThreeAcrossContainerFragment,
} from '../../../gatsby-graphql';
import ContentCard from '../ContentCard/ContentCard';

const ThreeAcrossContainer: React.FC<ThreeAcrossContainerFragment> = ({
  header,
  cards,
}) => (
  <Container>
    <ContentContainer>
      <h2>{header?.header}</h2>
      <CardsContainer>
        {cards?.map((card) => (
          <ContentCard
            key={card?.id as string}
            {...(card as ContentCardFragment)}
          />
        ))}
      </CardsContainer>
    </ContentContainer>
  </Container>
);

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.porcelain};
  padding-bottom: 50px;
  ${(props) => props.theme.mq.desktop} {
    padding-bottom: 100px;
  }
`;

const ContentContainer = styled(BaseContainer)`
  h2 {
    text-align: center;
    margin: 0 0 30px;
    ${(props) => props.theme.mq.desktop} {
      margin: 0 0 75px;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > div {
    width: 100%;
    margin: 0 0 30px;
  }
  ${(props) => props.theme.mq.tablet} {
    margin-right: -18px;
    margin-left: -18px;
    & > div {
      width: calc(50% - 36px);
      margin: 0 18px 36px;
    }
  }
  ${(props) => props.theme.mq.desktop} {
    margin-right: -20.5px;
    margin-left: -20.5px;
    & > div {
      width: calc(33.33% - 41px);
      margin: 0 20.5px 41px;
    }
  }
`;

export default ThreeAcrossContainer;
