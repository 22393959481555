import React from 'react';
import { IColors } from './ColorsGrid';
import ExpandedColorsGrid from './ExpadedColorsGrid';

export const colors: IColors[] = [
  {
    name: 'FIRE SLATE DK',
    codes: {
      CMYK: '79/64/52/42',
      HEX: ' #323F4A',
      RGB: '50/63/74',
    },
  },
  {
    name: 'Fire dark',
    codes: {
      HEX: '#D63D00',
      RGB: '212/65/39',
    },
  },
  {
    name: 'Yellow Dark',
    codes: {
      HEX: '#DB9D33',
      RGB: '219/157/51',
    },
  },
  {
    name: 'Sea Dark',
    codes: {
      HEX: '#35A09C',
      RGB: '53/160/156',
    },
  },
  {
    name: 'FM SLATE',
    codes: {
      CMYK: '76/60/45/25',
      HEX: '#435363',
      RGB: '67/83/99',
    },
  },
  {
    name: 'FM FIRE',
    codes: {
      CMYK: '0/84/100/0',
      HEX: '#FF4C00',
      RGB: '255/76/0',
    },
  },
  {
    name: 'FM YELLOW',
    codes: {
      CMYK: '8/21/65/0',
      HEX: '#EBC959',
      RGB: '235/201/89',
    },
  },
  {
    name: 'FM SEA',
    codes: {
      CMYK: '53/0/23/0',
      HEX: '#64CCC9',
      RGB: '100/204/201',
    },
  },
  {
    name: 'Slate MD',
    codes: {
      HEX: '#687582',
      RGB: '104/117/130',
    },
  },
  {
    name: 'Fire LT',
    codes: {
      HEX: '#F4967B',
      RGB: '244/150/23',
    },
  },
  {
    name: 'Yellow LT',
    codes: {
      HEX: '#F2DEB3',
      RGB: '242/222/179',
    },
  },
  {
    name: 'Sea LT',
    codes: {
      HEX: '#E1F6F4',
      RGB: '226/243/242',
    },
  },
  {
    name: 'FM SLATE LT',
    codes: {
      CMYK: '48/33/27/1',
      HEX: '#8B99A6',
      RGB: '139/153/166',
    },
  },
  {
    name: 'Orange Dark',
    codes: {
      HEX: '#F59538',
      RGB: '245/149/56',
    },
  },
  {
    name: 'Teal Dark',
    codes: {
      HEX: '#1F5F5D',
      RGB: '31/95/93',
    },
  },
  {
    name: 'Mint Dark',
    codes: {
      HEX: '#61C282',
      RGB: '97/194/130',
    },
  },
  {
    name: 'Grey LT 80',
    codes: {
      HEX: '#A2ADB7',
      RGB: '162/173/183',
    },
  },
  {
    name: 'FM ORANGE',
    codes: {
      CMYK: '0/48/88/0',
      HEX: '#F79737',
      RGB: '247/151/55',
    },
  },
  {
    name: 'Teal',
    codes: {
      HEX: '#297A76',
      RGB: '41/122/118',
    },
  },
  {
    name: 'FM MINT',
    codes: {
      CMYK: '38/0/38/0',
      HEX: '#A0D8B3',
      RGB: '160/216/1793',
    },
  },
  {
    name: 'Grey LT 60',
    codes: {
      HEX: '#B9C1C9',
      RGB: '185/193/201',
    },
  },
  {
    name: 'Orange LT',
    codes: {
      HEX: '#F7D18F',
      RGB: '247/209/143',
    },
  },
  {
    name: 'Teal LT',
    codes: {
      HEX: '#81B8B6',
      RGB: '129/184/182',
    },
  },
  {
    name: 'Mint LT',
    codes: {
      HEX: '#C6E8D1',
      RGB: '198/232/209',
    },
  },
];

const BlendingColors: React.FC = () => <ExpandedColorsGrid colors={colors} />;

export default BlendingColors;
