import React from 'react';
import AlertModule, { IAlertItems } from './AlertModule';

const items: IAlertItems[] = [
  {
    id: '01',
    type: 'Warnings',
    color: 'guardsmanRed',
    title: 'Alert Title',
    content:
      'Donec ullamcorper nulla non metus auctor fringilla. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui.',
  },
  {
    id: '02',
    type: 'Success',
    color: 'success',
    title: 'Alert Title',
    content:
      'Donec ullamcorper nulla non metus auctor fringilla. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui.',
  },
  {
    id: '03',
    type: 'Notice',
    color: 'fiord',
    title: 'Alert Title',
    content:
      'Donec ullamcorper nulla non metus auctor fringilla. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui.',
  },
];

const Alerts = () => <AlertModule items={items} />;

export default Alerts;
