import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../../icons';
import { BaseCard } from '../../BaseComponents';
import ModalCard, { IModalCards } from './ModalCard';

export interface IModalProps {
  headline: string;
  description: string;
  cards?: IModalCards[];
  footerContent: string;
  buttonLabel: string;
}

const ModalWrapper: React.FC<IModalProps> = ({
  headline,
  description,
  footerContent,
  buttonLabel,
  cards,
}) => (
  <ModalContainer
    role="dialog"
    aria-hidden="false"
    aria-labelledby="modalTitle"
    aria-describedby="modalDescription"
  >
    <CloseButton title="Close modal">
      {/*@ts-expect-error: we are auto-generating React components from svg files*/}
      <CloseIcon />
    </CloseButton>
    <h2 id="modalTitle">{headline}</h2>
    <p id="modalDescription">{description}</p>
    {cards && (
      <ModalCards>
        {cards.map((card, index) => (
          <ModalCard key={index} {...card} />
        ))}
      </ModalCards>
    )}
    <hr />
    <ModalFooter>
      <small>{footerContent}</small>
      <ModalButton>{buttonLabel}</ModalButton>
    </ModalFooter>
  </ModalContainer>
);

const ModalContainer = styled(BaseCard)`
  padding: 40px;
  margin-block-start: 40px;
  ${(props) => props.theme.mq.desktop} {
    padding: 60px;
  }
  h2 {
    margin-block-end: 20px;
    font-family: ${(props) => props.theme.fonts.GTSectra};
    font-size: 34px;
  }
  p {
    margin-block-start: 0;
  }
  hr {
    margin-block-start: 0 !important;
    margin-block-end: 20px;
  }
`;

const ModalCards = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block-end: 20px;
  flex-direction: column;
  ${(props) => props.theme.mq.tablet} {
    flex-direction: row;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: end;
  padding-block: 6px;
  margin-block-end: 20px;
  margin-inline-start: auto;
  path {
    fill: ${(props) => props.theme.colors.fiord};
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-block-start: 20px;
  gap: 20px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 10px;
  line-height: 1.4;
  ${(props) => props.theme.mq.tablet} {
    flex-direction: row;
  }
`;

const ModalButton = styled.button`
  background-color: ${(props) => props.theme.colors.grenadier};
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  padding: 12px 50px;
  border: none;
  letter-spacing: 0.2em;
  text-transform: Uppercase;
`;

export default ModalWrapper;
