import React from 'react';
import styled from 'styled-components';

export interface IModalCards {
  title: string;
  description: string;
  link: {
    label: string;
    url: string;
  };
}

const ModalCard: React.FC<IModalCards> = ({ title, description, link }) => (
  <ModalCardContainer>
    <ModalCardPicture />
    <h3>{title}</h3>
    <p>{description}</p>
    <a href={link.url}>{link.label}</a>
  </ModalCardContainer>
);

const ModalCardPicture = styled.div`
  background-color: ${(props) => props.theme.colors.slateLight};
  block-size: 89px;
  inline-size: 97px;
  margin-block-end: 10px;
`;

const ModalCardContainer = styled.div`
  margin-block-end: 20px;
  h3 {
    font-size: 14px;
  }
  h3,
  p {
    color: ${(props) => props.theme.colors.fiord};
    margin: 0 !important;
  }
  a {
    color: ${(props) => props.theme.colors.grenadier};
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.2em;
    text-decoration: none;
    text-transform: Uppercase;
  }
`;

export default ModalCard;
