import React from 'react';
import styled from 'styled-components';
import { ILinkProps } from './TextLink';
import TextLinkGroup, { ILinkGroupProps } from './TextLinkGroup';

const largeLinks: ILinkProps[] = [
  {
    linkText: 'Large text link',
    label: 'Hover over link to show interactive state',
    size: 'large',
  },
];

const smallLinks: ILinkProps[] = [
  {
    linkText: 'Small text link',
    label: 'Hover over link to show interactive state',
    size: 'small',
  },
];

const largeLightLinkGroup: ILinkGroupProps = {
  title: 'Large Text Link - On Light',
  type: 'light',
  links: largeLinks,
};

const smallLightLinkGroup: ILinkGroupProps = {
  title: 'Small Text Link - On Light',
  type: 'light',
  links: smallLinks,
};

const largeDarkLinkGroup: ILinkGroupProps = {
  title: 'Large Text Link - On Dark',
  type: 'dark',
  links: largeLinks,
};

const smallDarkLinkGroup: ILinkGroupProps = {
  title: 'Small Text Link - On Dark',
  type: 'dark',
  links: smallLinks,
};

const Links: React.FC = () => (
  <>
    <Row>
      <TextLinkGroup {...largeLightLinkGroup} />
      <TextLinkGroup {...smallLightLinkGroup} />
    </Row>
    <Row>
      <TextLinkGroup {...largeDarkLinkGroup} />
      <TextLinkGroup {...smallDarkLinkGroup} />
    </Row>
  </>
);

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-block-end: 36px;
  ${(props) => props.theme.mq.tablet} {
    flex-direction: row;
  }
`;

export default Links;
