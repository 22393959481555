import React from 'react';
import styled from 'styled-components';
import { BulletListContainerFragment } from '../../../gatsby-graphql';
import { BaseCard } from '../BaseComponents';

const BulletListContainer: React.FunctionComponent<BulletListContainerFragment> = ({
  header,
  list,
}) => (
  <Container>
    <HeaderContainer>
      <h3>{header?.header}</h3>
    </HeaderContainer>
    <List>
      {list?.map((listItem) => (
        <li key={listItem?.id as string}>{listItem?.copy?.copy}</li>
      ))}
    </List>
  </Container>
);

const Container = styled(BaseCard)`
  margin: 60px 0 0;
`;

const HeaderContainer = styled.div`
  padding: 26px 30px;
  ${(props) => props.theme.mq.desktop} {
    padding: 26px 43px;
  }
  background-color: ${(props) => props.theme.colors.fiord};

  h3 {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 14px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29 !important;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.white} !important;
    margin: 0 !important;
  }
`;

const List = styled.ul`
  list-style: none inside;
  padding: 14px 54px 48px;
  ${(props) => props.theme.mq.desktop} {
    padding: 14px 68px 48px;
  }
  margin: 0;
  li {
    margin-top: 20px;
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.fiord};
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: -27px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid ${(props) => props.theme.colors.fiord};
    }
  }
`;

export default BulletListContainer;
