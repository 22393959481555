import React from 'react';
import Typography, { ITypographyProps } from './Typography';

const typographyData: ITypographyProps = {
  fontName: 'Whitney SSm',
  footerText: 'Whitney SSm is developed by Hoefler & Co.',
};

const WhitneyTypeface: React.FC = () => <Typography {...typographyData} />;

export default WhitneyTypeface;
