import React from 'react';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { Options } from '@contentful/rich-text-react-renderer';
import { GatsbyImage } from 'gatsby-plugin-image';
import GenericContentEmbeddedEntry from '../GenericContentEmbeddedEntry/GenericContentEmbeddedEntry';

interface IGenericContentRifRenderProps {
  data: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  divisionLines: boolean;
}

const GenericContentRifRender: React.FunctionComponent<IGenericContentRifRenderProps> = ({
  data,
  divisionLines,
}) => {
  const options: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { target } = node.data;
        if (target.__typename === 'ContentfulWebGuideElement') {
          target.__typename = String(target.component).replace(/[ ]/g, '');
        }
        return (
          <GenericContentEmbeddedEntry
            __typename={target.__typename}
            props={target}
          />
        );
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <GatsbyImage
          image={node.data.target.gatsbyImageData}
          alt={node.data.target.description}
        />
      ),
    },
  };
  return (
    data && (
      <MainContent divisionLines={divisionLines}>
        {renderRichText(data, options)}
      </MainContent>
    )
  );
};

const MainContent = styled.div<{ divisionLines: boolean | undefined }>`
  color: ${(props) => props.theme.colors.limedSpruce};

  h4:first-of-type {
    margin-block-start: 30px;
  }

  h3 {
    margin: 40px 0 21px;
    color: ${(props) => props.theme.colors.fiord};
    &:not(:first-of-type) {
      ${(props) =>
        props.divisionLines &&
        `border-block-start: 1px solid ${props.theme.colors.alto};`}
      padding-block-start: 40px;
      margin-block-start: 20px;
    }
  }
  h4 {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 12px;
    letter-spacing: 0.2em;
    line-height: 1.83;
    text-transform: uppercase;
    margin-block-start: 40px;
  }

  .gatsby-image-wrapper {
    margin-block-start: 20px;
    & + .gatsby-image-wrapper {
      margin-top: 20px;
    }

    & + h3,
    & + h4,
    & + p + h3 {
      ${(props) =>
        props.divisionLines &&
        `border-block-start: 1px solid ${props.theme.colors.alto};`}
      padding-block-start: 40px;
    }
  }
  h4 + .gatsby-image-wrapper {
    margin-block-start: 0;
  }

  hr {
    border-block-start: 1px solid ${(props) => props.theme.colors.alto};
    border-block-end: none;
    margin-block-start: 20px;
  }
`;

export default GenericContentRifRender;
