import React from 'react';
import styled from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { GenericContentFragment } from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import GenericContentRifRender from './GenericContentRifRender';

interface IGenericContentProps extends GenericContentFragment {
  internalPageNavigation?: boolean;
}

const GenericContent = React.forwardRef<
  HTMLDivElement | null,
  IGenericContentProps
>(({ header, internalPageNavigation, mainContent, divisionLines }, ref) => (
  <Container ref={ref} internalPageNavigation={internalPageNavigation}>
    <h2>{header?.header}</h2>
    <GenericContentRifRender
      data={
        mainContent as RenderRichTextData<ContentfulRichTextGatsbyReference>
      }
      divisionLines={divisionLines as boolean}
    />
  </Container>
));

const Container = styled(BaseContainer)<{ internalPageNavigation?: boolean }>`
  h2 {
    margin-top: 0;
  }
  margin-top: 50px;
  margin-bottom: 50px;
  ${(props) => props.theme.mq.desktop} {
    ${(props) =>
      props.internalPageNavigation
        ? props.theme.sizes.sideNavigation.contentOffset
        : ''};
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

GenericContent.displayName = 'GenericContent';

export default GenericContent;
