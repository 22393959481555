import React from 'react';
import styled from 'styled-components';

export interface IColors {
  name: string;
  codes: {
    CMYK?: string;
    PMS?: string;
    RGB?: string;
    HEX: string;
  };
}

export interface IColorsGridProps {
  colors: IColors[];
  isExpanded?: boolean;
}

const ColorsGrid: React.FC<IColorsGridProps> = ({ colors, isExpanded }) => (
  <Container>
    {colors.map(({ codes, name }) => (
      <ColorContainer key={codes['HEX']} isExpanded={isExpanded}>
        <h4>{name}</h4>
        <ColorBox color={codes['HEX']} isExpanded={isExpanded} />
        {Object.keys(codes).map((code, i) => (
          <>
            <p>
              <CodeName isExpanded={isExpanded}>{code}</CodeName>{' '}
              <span>{codes[code]}</span>
            </p>
          </>
        ))}
      </ColorContainer>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.mq.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const ColorContainer = styled.div<{ isExpanded: boolean | undefined }>`
  margin-bottom: 25px;
  p {
    margin: 0;
  }

  span {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 12px;
    line-height: 1.33;
    color: #323f4a;
    margin-top: 15px;
    text-transform: none;
    & + span {
      margin-top: 10px;
    }
  }

  ${(props) => props.theme.mq.tablet} {
    flex-basis: calc(33.33% - 18.66px);
    margin-bottom: 30px;
    ${(props) =>
      !props.isExpanded &&
      ` &:not(:nth-of-type(3n + 3)) {
          margin-right: 28px;
        }`}

    ${(props) =>
      props.isExpanded &&
      `flex-basis: calc(50% - 22.66px);
       &:not(:nth-of-type(2n + 2)) {
         margin-right: 28px;
       }
       h4 {
         letter-spacing: 0!important;
         font-size: 14px;
       }`}
  }

  ${(props) => props.theme.mq.desktop} {
    ${(props) =>
      props.isExpanded &&
      `flex-basis: calc(25% - 22.66px);
       &:not(:nth-of-type(4n + 4)) {
       margin-right: 28px;
    }`}
  }
`;

const ColorBox = styled.div<{ color: string; isExpanded: boolean | undefined }>`
  height: ${(props) => (props.isExpanded ? '50px' : '100px')};
  width: 100%;
  background-color: ${(props) => props.color};
  margin-block-end: 20px;
`;

const CodeName = styled.span<{ isExpanded: boolean | undefined }>`
  font-weight: ${(props) => (props.isExpanded ? 400 : 700)};
`;
export default ColorsGrid;
