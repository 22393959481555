import React from 'react';
import styled from 'styled-components';
import { IColorsGridProps } from './ColorsGrid';

const ExpandedColorsGrid: React.FC<IColorsGridProps> = ({ colors }) => (
  <Container>
    {colors.map(({ codes, name }) => (
      <ColorContainer key={codes['HEX']}>
        <ColorBox color={codes['HEX']} />
        <ColorCodes>
          <h4>{name}</h4>
          {Object.keys(codes).map((code, i) => (
            <>
              <p>
                {code} <span>{codes[code]}</span>
              </p>
            </>
          ))}
        </ColorCodes>
      </ColorContainer>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.mq.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const ColorContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  gap: 9px;

  ${(props) => props.theme.mq.tablet} {
    flex-basis: calc(50% - 11.33px);
  }

  ${(props) => props.theme.mq.desktop} {
    flex-basis: calc(25% - 22.66px);
    margin-block-end: 20px;
    &:not(:nth-of-type(4n + 4)) {
      margin-right: 28px;
    }
  }
  h4 {
    margin: 0 !important;
    font-size: 14px !important;
    letter-spacing: 0 !important;
  }

  p {
    margin: 0;
  }

  span {
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 12px;
    line-height: 1.33;
    color: #323f4a;
    margin-top: 15px;
    text-transform: uppercase;
    & + span {
      margin-top: 10px;
    }
  }
`;

const ColorCodes = styled.div`
  padding: 0;
`;
const ColorBox = styled.div<{ color: string }>`
  height: 34px;
  width: 34px;
  background-color: ${(props) => props.color};
`;

export default ExpandedColorsGrid;
