import React from 'react';
import { IModalCards } from './ModalCard';
import ModalWrapper, { IModalProps } from './ModalWrapper';

const cards: IModalCards[] = [
  {
    title: 'Name, Last Name',
    description: 'Company or University',
    link: { label: 'Link', url: '#' },
  },
  {
    title: 'Name, Last Name',
    description: 'Company or University',
    link: { label: 'Link', url: '#' },
  },
  {
    title: 'Name, Last Name',
    description: 'Company or University',
    link: { label: 'Link', url: '#' },
  },
];

const modalData: IModalProps = {
  headline: 'Modal Headline',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, gravida interdum ut lectus vitae praesent tincidunt a.',
  footerContent:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non, tempor magna sapien ridiculus ullamcorper turpis pellentesque suspendisse.',
  buttonLabel: 'Button',
};

const modalDataWithCards: IModalProps = {
  headline: 'Modal Headline',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, gravida interdum ut lectus vitae praesent tincidunt a.',
  cards: cards,
  footerContent:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non, tempor magna sapien ridiculus ullamcorper turpis pellentesque suspendisse.',
  buttonLabel: 'Button',
};

const Modals: React.FC = () => (
  <>
    <ModalWrapper {...modalData} />
    <ModalWrapper {...modalDataWithCards} />
  </>
);

export default Modals;
