import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../styles/GlobalStyle';
import Seo from '../Seo/Seo';
import theme from '../../styles/theme';
import Footer from '../Footer/Footer';
import {
  FooterFragment,
  HeaderFragment,
  PageGlobalsFragment,
} from '../../../gatsby-graphql';
import Header from '../Header/Header';

interface ILayoutProps {
  footer: PageGlobalsFragment['footer'];
  header: PageGlobalsFragment['header'];
  path: string;
}

const Layout: React.FC<ILayoutProps> = ({ children, footer, header, path }) => (
  <>
    <Seo />
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header data={header as HeaderFragment} path={path} />
      <StyledMain>{children}</StyledMain>
      <Footer {...(footer as FooterFragment)} />
    </ThemeProvider>
  </>
);

const StyledMain = styled.main`
  min-height: 100vh;
`;

export default Layout;
