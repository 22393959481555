import React from 'react';
import OneColumnContentListing from '../OneColumnContentListing/OneColumnContentListing';
import GenericContent from '../GenericContent/GenericContent';

interface IComponents {
  [key: string]: React.ForwardedRef<any>;
}
const Components: IComponents = {
  OneColumnContentListing: OneColumnContentListing,
  GenericContent: GenericContent,
};

export default Components;
