import React from 'react';
import styled from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { ContentRowFragment } from '../../../gatsby-graphql';

interface IContentRow extends ContentRowFragment {
  className?: string;
  isBulletList?: boolean;
}

const ContentRow: React.FC<IContentRow> = ({
  title,
  description,
  ...props
}) => (
  <Container {...props}>
    <h3>{title?.title}</h3>
    {description &&
      renderRichText(
        description as RenderRichTextData<ContentfulRichTextGatsbyReference>
      )}
  </Container>
);

const Container = styled.div`
  p {
    margin: 0;
    & + p {
      margin-top: 12px;
    }
  }
`;

export default ContentRow;
