import React from 'react';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FullWidthImageFragment } from '../../../gatsby-graphql';
import { BaseCard, BaseContainer } from '../BaseComponents';

const FullWidthImage: React.FC<FullWidthImageFragment> = ({
  image,
  header,
  description,
  coloredBackground,
}) => (
  <Container coloredBackground={coloredBackground}>
    <GatsbyImage
      alt={image?.description as string}
      image={image?.gatsbyImageData}
    />
    {header && description && (
      <ContentContainer>
        <ContentCard>
          <h2>{header?.header}</h2>
          {renderRichText(
            description as RenderRichTextData<ContentfulRichTextGatsbyReference>
          )}
        </ContentCard>
      </ContentContainer>
    )}
  </Container>
);

const Container = styled.div<{ coloredBackground: boolean }>`
  background-color: ${(props) =>
    props.coloredBackground
      ? props.theme.colors.porcelain
      : props.theme.colors.white};
  padding-bottom: 50px;
  ${(props) => props.theme.mq.desktop} {
    padding-bottom: 100px;
  }
`;

const ContentContainer = styled(BaseContainer)`
  position: relative;
  z-index: 1;
  margin-top: -40px;
  width: 100%;
  ${(props) => props.theme.mq.desktop} {
    margin-top: -190px;
  }
`;

const ContentCard = styled(BaseCard)`
  padding: 30px 30px 40px;
  ${(props) => props.theme.mq.desktop} {
    padding: 100px 155px 108px;
    max-width: 993px;
    margin: 0 auto;
  }
  & > h2 {
    margin: 0 0 30px;
  }
  p {
    margin: 0;
    & + p {
      margin-bottom: 14px;
    }
    a {
      text-decoration-color: ${(props) => props.theme.colors.grenadier};
    }
  }
`;

export default FullWidthImage;
