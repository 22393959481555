import { LinkFragment } from '../../gatsby-graphql';

export const chunkArray = (array: any[], size: number): any[] => {
  const result: any[] = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

export const isBuildTime = typeof window === 'undefined';

export const _window: Window = isBuildTime ? ({} as Window) : window;

export const linkHasUrl = (link: LinkFragment): boolean => Boolean(link?.url);

export const isExternalUrl = (url: LinkFragment['url']): boolean =>
  url?.__typename === 'ContentfulExternalUrl';

export const getLinkTo = (url: LinkFragment['url']): string =>
  String(url?.__typename === 'ContentfulExternalUrl' ? url?.url : url?.path);

export const getLinkTarget = (url: LinkFragment['url']): string =>
  String(
    url?.__typename === 'ContentfulExternalUrl'
      ? url?.opensInNewTab
        ? '_blank'
        : '_self'
      : '_self'
  );
