import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { BaseContainer } from '../BaseComponents';
import { HeaderFragment } from '../../../gatsby-graphql';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

const Header: React.FC<{ data: HeaderFragment; path: string }> = ({
  data,
  path,
}) => {
  const {
    logo,
    navigation,
    mobileCloseMenuAssistiveLabel,
    mobileOpenMenuAssistiveLabel,
    mobileExpandedMenuCloseLabel,
    logoLink,
    logoLinkAssistiveLabel,
  } = data;
  const images = withArtDirection(logo?.desktopImage, [
    {
      media: '(max-width: 1024px)',
      image: logo?.mobileImage,
    },
  ]);
  return (
    <header>
      <Container>
        <Link
          to={logoLink?.path as string}
          title={logoLinkAssistiveLabel as string}
        >
          <GatsbyImage
            className="logo"
            alt={logo?.description as string}
            image={images}
          />
        </Link>
        <nav>
          <MobileNav
            {...{
              navigation,
              mobileCloseMenuAssistiveLabel,
              mobileOpenMenuAssistiveLabel,
              mobileExpandedMenuCloseLabel,
            }}
          />
          <DesktopNav navigation={navigation} path={path} />
        </nav>
      </Container>
    </header>
  );
};

const Container = styled(BaseContainer)`
  height: ${(props) => props.theme.sizes.mobileHeaderHeight}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  ${(props) => props.theme.mq.desktop} {
    height: auto;
    padding-top: 36px;
    margin-bottom: 94px;
    align-items: center;
  }
  .logo {
    @media screen and (max-width: 1024px) {
      width: 158px;
      height: 34px;
    }
  }
  nav {
    display: flex;
    align-items: center;
  }
`;

export default Header;
