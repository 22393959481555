import { css } from 'styled-components';

const fonts = css`
  @font-face {
    font-family: 'GT Sectra';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/GT-Sectra-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'GT Sectra';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/GT-Sectra-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'GT Sectra';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/GT-Sectra-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'GT Sectra Book';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/GT-Sectra-Book.woff') format('woff');
  }

  @font-face {
    font-family: 'GT Sectra Book';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/GT-Sectra-Book-Italic.woff') format('woff');
  }
`;

export default fonts;
