import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { ChevronRightIcon } from '../../icons';
import { GroupLinkFragment, LinkFragment } from '../../../gatsby-graphql';
import SiteLink from '../SitelLink/SiteLink';

const DesktopHeaderGroupLink: React.FC<{
  data: GroupLinkFragment;
  path: string;
}> = ({ data, path }) => {
  const { placeholder, selectedLabel, links } = data;

  const dropdownEl = useRef<HTMLDivElement>(null);
  const [selectedLink, setSelectedLink] = useState<LinkFragment>();
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const selectSpring = useSpring({
    height: isSelectOpen ? 'auto' : 0,
    overflow: isSelectOpen ? 'visible' : 'hidden',
  });

  useEffect(() => {
    const handleClickOutside = (e) => {
      const container = dropdownEl.current;

      if (
        container &&
        container !== e.target &&
        !container.contains(e.target)
      ) {
        setIsSelectOpen(false);
      }
    };

    document.addEventListener('click', (e) => handleClickOutside(e));

    return () => {
      document.removeEventListener('click', (e) => handleClickOutside(e));
    };
  }, []);

  useEffect(() => {
    links?.forEach((link) => {
      if (link?.url?.path === path) {
        setSelectedLink(link);
      }
    });
  }, [path]);

  const handleSelectTriggerClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsSelectOpen((prev) => !prev);
  };

  return (
    <SelectContainer ref={dropdownEl}>
      <SelectButton aria-haspopup="listbox" onClick={handleSelectTriggerClick}>
        <span>
          {selectedLink
            ? selectedLabel
              ? `${selectedLabel} ${selectedLink.label}`
              : selectedLink.label
            : placeholder}
        </span>
        {/*@ts-expect-error: SVG is autogenerated by Gatsby*/}
        <ChevronRightIcon />
      </SelectButton>
      <CustomSelect role="listbox" style={selectSpring} isOpen={isSelectOpen}>
        {links?.map((link, index) => (
          <SiteLink key={index} {...(link as LinkFragment)} tabIndex={-1} />
        ))}
      </CustomSelect>
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: '100%';
  min-width: 306px;
  margin: 0 auto;
  z-index: 1;

  select {
    display: none;
  }
`;

const SelectButton = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border: solid 1px #d4d6d9;
  cursor: pointer;
  padding: 12px 20px;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 14px;
  line-height: 1.86;
  color: ${(props) => props.theme.colors.limedSpruce};
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
    padding-right: 20px;
  }
  svg {
    width: 7px;
    height: 10px;
    transform: rotate(90deg);
  }
  &:focus {
    outline: none;
    border-color: #8b99a6;
  }
`;

const CustomSelect = styled(animated.div)<{ isOpen?: boolean }>`
  display: flex;
  background: ${(props) => props.theme.colors.white};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  ${(props) =>
    props.isOpen &&
    css`
      border: solid 1px #8b99a6;
      border-top: 0;
    `}

  a {
    color: ${(props) => props.theme.colors.limedSpruce};
    border: none;
    background: transparent;
    text-align: left;
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 14px;
    line-height: 1.86;
    padding: 12px 20px;
    text-decoration: none;

    &:hover {
      background: #ebf8f8;
      font-weight: 700;
      color: ${(props) => props.theme.colors.paradiso};
    }
  }
`;

export default DesktopHeaderGroupLink;
