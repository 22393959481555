import React from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { ContentCardFragment } from '../../../gatsby-graphql';
import { BaseBorderCard } from '../BaseComponents';

const ContentCard: React.FC<ContentCardFragment> = ({ title, description }) => {
  const { colors } = useTheme();
  return (
    <Container borderColor={colors.downy}>
      <div>
        <h3>{title}</h3>
        {description &&
          renderRichText(
            description as RenderRichTextData<ContentfulRichTextGatsbyReference>
          )}
      </div>
    </Container>
  );
};

const Container = styled(BaseBorderCard)`
  & > div {
    padding: 40px 30px 40px;
    ${(props) => props.theme.mq.desktop} {
      padding: 55px 37px 50px;
    }
  }
  h3 {
    text-align: center;
    color: ${(props) => props.theme.colors.limedSpruce};
    margin: 0 0 16px;
  }
  p {
    font-size: 12px;
    line-height: 1.83;
    text-align: center;
    color: ${(props) => props.theme.colors.fiord};
    & + p {
      margin-top: 12px;
    }
  }
`;

export default ContentCard;
