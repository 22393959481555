import React from 'react';
import styled from 'styled-components';

export interface ILinkProps {
  label: string;
  linkText: string;
  hover?: boolean;
  active?: boolean;
  size: 'large' | 'small';
}

const TextLink: React.FC<ILinkProps> = ({
  label,
  linkText,
  hover,
  active,
  size,
}) => (
  <LinkContainer>
    <LinkText hover={hover} active={active} size={size}>
      {linkText}
    </LinkText>
    <p>{label}</p>
  </LinkContainer>
);

const LinkContainer = styled.div`
  padding-inline: 10px;
  p {
    font-size: 10px;
    margin-block-end: 14px;
    margin-block-start: 9px;
  }
`;

const LinkText = styled.a<{
  hover: boolean | undefined;
  active: boolean | undefined;
  size: 'small' | 'large';
}>`
  font-family: ${(props) => props.theme.fonts.GTSectra};
  font-size: ${(props) => (props.size === 'large' ? '21px' : '14px')};
  text-decoration: underline;
  text-decoration-color: ${(props) => props.theme.colors.fireDark};
  text-decoration-thickness: 1px;
  text-underline-offset: 8px;
  cursor: pointer;

  &:hover {
    text-decoration-thickness: 2px;
  }
`;

export default TextLink;
