import React from 'react';
import BulletListContainer from '../BulletListContainer/BulletListContainer';
import PrimaryWebColors from '../WebGuide/Colors/PrimaryWebColors';
import SecondaryWebColors from '../WebGuide/Colors/SecondaryWebColors';
import GrayWebColors from '../WebGuide/Colors/GrayWebColors';
import UIColors from '../WebGuide/Colors/UIColors';
import TwoColumnImages from '../TwoColumnImages/TwoColumnImages';
import Accordions from '../WebGuide/Accordions';
import Tabs from '../WebGuide/Tabs';
import Alerts from '../WebGuide/Alerts/Alerts';
import Modals from '../WebGuide/Modals/Modals';
import TwoColumnContent from '../TwoColumnContent/TwoColumnContent';
import Inputs from '../WebGuide/Inputs/Inputs';
import Links from '../WebGuide/Links/Links';
import ButtonLinks from '../WebGuide/ButtonLinks/ButtonLinks';
import Buttons from '../WebGuide/Buttons/Buttons';
import GothamTypeface from '../WebGuide/Typography/GothamTypeface';
import GTSectraTypeface from '../WebGuide/Typography/GTSectraTypeface';
import WhitneyTypeface from '../WebGuide/Typography/WhitneyTypeface';
import PrimaryBrandColors from '../WebGuide/Colors/PrimaryBrandColors';
import SecondaryBrandColors from '../WebGuide/Colors/SecondaryBrandColors';
import BlendingColors from '../WebGuide/Colors/BlendingColors';
import ExpandedPallete from '../WebGuide/Colors/ExpandedPallete';
import TwoColumnRightImage from '../TwoColumnRightImage/TwoColumnRightImage';
import Tints from '../WebGuide/Colors/Tints';

interface IComponents {
  [key: string]: React.FC<any> | React.FunctionComponent<any>;
}
const Components: IComponents = {
  TwoColumnRightImage: TwoColumnRightImage,
  TwoColumnImages: TwoColumnImages,
  TwoColumnContent: TwoColumnContent,
  BulletListContainer: BulletListContainer,
  Accordions: Accordions,
  Tabs: Tabs,
  Alerts: Alerts,
  FormInputs: Inputs,
  Buttons: Buttons,
  Modals: Modals,
  Links: Links,
  ButtonLinks: ButtonLinks,
  GothamTypeface: GothamTypeface,
  GTSectraTypeface: GTSectraTypeface,
  WhitneyTypeface: WhitneyTypeface,
  PrimaryWebColors: PrimaryWebColors,
  SecondaryWebColors: SecondaryWebColors,
  GrayWebColors: GrayWebColors,
  UIColors: UIColors,
  PrimaryBrandColors: PrimaryBrandColors,
  SecondaryBrandColors: SecondaryBrandColors,
  BlendingColors: BlendingColors,
  ExpandedColorPallete: ExpandedPallete,
  Tints: Tints,
};

export default Components;
