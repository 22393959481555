import React from 'react';
import styled from 'styled-components';
import {
  TwoColumnImagesFragment,
  TwoColumnImagesRowFragment,
} from '../../../gatsby-graphql';
import TwoColumnImagesRow from './TwoColumnImagesRow';

const TwoColumnImages: React.FunctionComponent<TwoColumnImagesFragment> = ({
  rows,
}) => (
  <Container>
    {rows?.map((rowItem) => (
      <TwoColumnImagesRow
        key={rowItem?.id}
        {...(rowItem as TwoColumnImagesRowFragment)}
      />
    ))}
  </Container>
);

const Container = styled.div``;

export default TwoColumnImages;
