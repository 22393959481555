import React, { useContext, useEffect, useRef } from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { InternalPageNavigationFragment } from '../../../gatsby-graphql';
import { _window } from '../../utils';
import ReactComponents from './Components';
import SideNavigationContext from './context/SideNavigationContext';
interface IPageContentProps {
  components?: InternalPageNavigationFragment['components'] | null;
}

const InternalPageNavigationComponents: React.FC<IPageContentProps> = ({
  components,
}) => {
  const waypointsRefs = useRef<HTMLElement[]>([]);
  const { indexToScroll, setActiveNavigationItem, parentRef } = useContext(
    SideNavigationContext
  );

  useEffect(() => {
    if (
      indexToScroll !== null &&
      waypointsRefs.current[indexToScroll] &&
      parentRef.current
    ) {
      const currentComponent = waypointsRefs.current[indexToScroll];
      const elementOffsetTop = currentComponent.offsetTop;
      const parentOffsetTop = parentRef.current.offsetTop;
      // Get marginTop of the element
      const { marginTop: strMarginTop } = window.getComputedStyle(
        currentComponent
      );
      const marginTop = Number(strMarginTop.replace('px', ''));
      const newTopPosition =
        // If first elements scroll to the top of the page
        indexToScroll === 0
          ? elementOffsetTop - 100
          : // If mobile/tablet subtract fixed dropdown height
          window.innerWidth <= 1024
          ? elementOffsetTop + parentOffsetTop - marginTop - 52
          : elementOffsetTop + parentOffsetTop - marginTop;
      window.scrollTo({
        top: newTopPosition,
        behavior: 'smooth',
      });
    }
  }, [indexToScroll]);

  return (
    <Container>
      {components?.map((component, index) => {
        const ComponentName = component?.__typename.replace('Contentful', '');
        if (!ComponentName || !ReactComponents[ComponentName]) return null;
        const Component = ReactComponents[ComponentName];
        return (
          <React.Fragment key={`${component?.__typename}-${index}`}>
            <Waypoint
              scrollableAncestor={_window}
              bottomOffset="30%"
              topOffset="30%"
              onEnter={() => setActiveNavigationItem(index)}
            >
              {/*@ts-expect-error: Component is dynamic*/}
              <Component
                key={`${component?.__typename}-${index}`}
                ref={(el) => (waypointsRefs.current[index] = el)}
                internalPageNavigation={true}
                {...component}
              />
            </Waypoint>
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  & > div {
    &:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }
  }
`;

export default InternalPageNavigationComponents;
