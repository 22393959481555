import React from 'react';
import styled from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GenericFullWidthCalloutFragment } from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';

const GenericFullWidthCallout: React.FC<GenericFullWidthCalloutFragment> = ({
  header,
  description,
}) => {
  const options: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <GatsbyImage
          image={node.data.target.gatsbyImageData}
          alt={node.data.target.description}
        />
      ),
    },
  };

  return (
    <Container>
      {header && <h2>{header?.header}</h2>}
      <div>
        {renderRichText(
          description as RenderRichTextData<ContentfulRichTextGatsbyReference>,
          options
        )}
      </div>
    </Container>
  );
};

const Container = styled(BaseContainer)`
  padding-top: 50px;
  padding-bottom: 50px;
  ${(props) => props.theme.mq.desktop} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  h2 {
    text-align: center;
    margin: 0 0 32px;
  }
  & > div {
    max-width: 700px;
    margin: 0 auto;
    p {
      font-family: ${(props) => props.theme.fonts.GTSectra};
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: ${(props) => props.theme.colors.limedSpruce};
      ${(props) => props.theme.mq.desktop} {
        font-size: 21px;
        line-height: 1.57;
      }
      & + p {
        margin-top: 12px;
      }
    }
    a {
      text-decoration-color: ${(props) => props.theme.colors.grenadier};
    }
  }
`;

export default GenericFullWidthCallout;
