import React from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BaseContainer } from '../BaseComponents';
import { MessageBannerFragment } from '../../../gatsby-graphql';

const MessageBanner: React.FunctionComponent<MessageBannerFragment> = ({
  message,
  backgroundColor: managedBackgroundColor,
}) => {
  const { colors } = useTheme();
  const backgroundColor = {
    white: colors.white,
    green: colors.paradiso,
    gray: colors.porcelain,
  };
  const foregroundColor = {
    white: colors.fiord,
    green: colors.white,
    gray: colors.fiord,
  };
  return (
    <Container
      style={{
        backgroundColor: backgroundColor[managedBackgroundColor as string],
        color: foregroundColor[managedBackgroundColor as string],
      }}
    >
      <Content>
        {renderRichText(
          message as RenderRichTextData<ContentfulRichTextGatsbyReference>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  padding: 22px 0;
`;

const Content = styled(BaseContainer)`
  p {
    margin: 0;
    color: #ffffff;
  }
  ${(props) => props.theme.mq.tablet} {
    text-align: center;
  }
`;

export default MessageBanner;
