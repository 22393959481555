import React from 'react';
import Typography, { ITypographyProps } from './Typography';

const typographyData: ITypographyProps = {
  fontName: 'GT Sectra',
  footerText: 'GT Sectra is developed by Grilli Type',
};

const GTSectraTypeface: React.FC = () => <Typography {...typographyData} />;

export default GTSectraTypeface;
