import React, { useState } from 'react';
import styled from 'styled-components';
import { MinusIcon, PlusIcon } from '../../icons';

export interface IAccordionTabsItemsProps {
  id: string;
  label: string;
  content: string;
}

const AccordionItem: React.FC<IAccordionTabsItemsProps> = ({
  id,
  label,
  content,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleActiveItem = () => {
    setIsActive(!isActive);
  };
  return (
    <Container>
      <AccordionHeader onClick={handleActiveItem} active={isActive}>
        <h3>
          <AccordionControl
            id={`accordion-${id}`}
            aria-controls={`region-${id}`}
            aria-expanded={isActive}
          >
            <AccordionTitle>
              {label}
              <AccordionIcon>
                {/*@ts-expect-error: we are auto-generating React components from svg files*/}
                {isActive ? <MinusIcon /> : <PlusIcon />}
              </AccordionIcon>
            </AccordionTitle>
          </AccordionControl>
        </h3>
      </AccordionHeader>
      {isActive && (
        <AccordionBody
          role="region"
          id={`region-${id}`}
          aria-labelledby={`accordion-${label}`}
          tabIndex={0}
        >
          <p>{content}</p>
        </AccordionBody>
      )}
    </Container>
  );
};
const Container = styled.div`
  h3 {
    color: white;
    margin: 0 !important;
    width: 100%;
  }
  h3 span {
    font-weight: bold;
    font-size: 14px;
  }
  p {
    color: white;
  }
`;
const AccordionHeader = styled.div<{ active: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-block: 14px;
  position: relative;
`;
const AccordionControl = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  color: white;
  padding: 0;
`;
const AccordionTitle = styled.span`
  display: block;
  text-align: left;
`;
const AccordionIcon = styled.span`
  position: absolute;
  right: 0;
  font-size: 24px;
  color: ${(props) => props.theme.colors.ronchi};
`;
const AccordionBody = styled.div`
  margin-block-start: 20px;
`;
export default AccordionItem;
