import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FooterFragment, GroupLinkFragment } from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import FooterGroupLink from './FooterGroupLink';

const Footer: React.FC<FooterFragment> = ({ logo, multiColumn, copyright }) => {
  const handleLogoClick = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <footer>
      <FooterContent>
        <GatsbyImage
          onClick={handleLogoClick}
          alt={logo?.description as string}
          image={logo?.gatsbyImageData}
          loading="eager"
        />
        <FooterMultiColumn>
          {multiColumn?.map((column) => (
            <FooterGroupLink
              key={column?.id}
              {...(column as GroupLinkFragment)}
            />
          ))}
        </FooterMultiColumn>
      </FooterContent>
      <CopyrightContainer>
        <BaseContainer>
          <span>{`© ${new Date().getFullYear()} ${copyright}`}</span>
        </BaseContainer>
      </CopyrightContainer>
    </footer>
  );
};

const FooterContent = styled(BaseContainer)`
  border-top: 1px solid ${(props) => props.theme.colors.alto};
  padding-top: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(props) => props.theme.mq.desktop} {
    align-items: flex-start;
    flex-direction: row;
  }
  img {
    cursor: pointer;
  }
`;

const FooterMultiColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 32px 0 0;
  ${(props) => props.theme.mq.desktop} {
    width: auto;
    flex: 1;
    margin: 0 0 0 59px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
`;

const CopyrightContainer = styled.div`
  background-color: ${(props) => props.theme.colors.porcelain};
  padding: 28px 0 20px;
  margin-top: 62px;
  span {
    font-family: 'Gotham A', 'Gotham B', arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.fiord};
  }
`;

export default Footer;
