import React from 'react';
import TabsModule, { ITabsItems } from '../TabsModule/TabsModule';

const items: ITabsItems[] = [
  {
    id: '01',
    title: 'Donec ullamcorper nulla non metus auctor fringilla.1',
    label: 'Label',
    description:
      'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit.Sed posuere consectetur est at lobortis.Donec id elit non mi porta gravida at eget metus.Nullam quis risus eget urna mollis ornare vel eu leo.Maecenas faucibus mollis interdum. Etiam porta sem malesuada magna mollis euismod.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Aenean eu leo quam.Pellentesque ornare sem lacinia quam venenatis vestibulum.Cras justo odio, dapibus ac facilisis in, egestas eget quam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    id: '02',
    title: 'Donec ullamcorper nulla non metus auctor fringilla.2',
    label: 'Label',
    description:
      'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit.Sed posuere consectetur est at lobortis.Donec id elit non mi porta gravida at eget metus.Nullam quis risus eget urna mollis ornare vel eu leo.Maecenas faucibus mollis interdum. Etiam porta sem malesuada magna mollis euismod.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Aenean eu leo quam.Pellentesque ornare sem lacinia quam venenatis vestibulum.Cras justo odio, dapibus ac facilisis in, egestas eget quam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    id: '03',
    label: 'Label',
    title: 'Donec ullamcorper nulla non metus auctor fringilla.3',
    description:
      'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit.Sed posuere consectetur est at lobortis.Donec id elit non mi porta gravida at eget metus.Nullam quis risus eget urna mollis ornare vel eu leo.Maecenas faucibus mollis interdum. Etiam porta sem malesuada magna mollis euismod.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Aenean eu leo quam.Pellentesque ornare sem lacinia quam venenatis vestibulum.Cras justo odio, dapibus ac facilisis in, egestas eget quam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
];

const Tabs = () => <TabsModule items={items} />;

export default Tabs;
