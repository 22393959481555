import React from 'react';
import Typography, { ITypographyProps } from './Typography';

const typographyData: ITypographyProps = {
  fontName: 'Gotham',
  footerText: 'Gotham is developed by Hoefler & Co.',
};

const GothamTypeface: React.FC = () => <Typography {...typographyData} />;

export default GothamTypeface;
