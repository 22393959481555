import React, { createContext } from 'react';

export interface ISideNavigationContext {
  activeNavigationItem: number;
  setActiveNavigationItem: React.Dispatch<React.SetStateAction<number>>;
  indexToScroll: number | null;
  scrollToItem(index: number): void;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
}

const SideNavigationContext = createContext<ISideNavigationContext>({
  activeNavigationItem: 0,
  setActiveNavigationItem: () => null,
  scrollToItem: () => null,
  indexToScroll: null,
  parentRef: { current: null },
});

export default SideNavigationContext;
