import React from 'react';
import styled from 'styled-components';

export interface IInputFieldProps {
  placeholder: string;
  label?: string;
  isSuccess?: boolean;
  isValid?: boolean;
  isFocus?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  successMessage?: string;
  name: string;
}

const InputField: React.FC<IInputFieldProps> = ({
  label,
  placeholder,
  isSuccess,
  isValid,
  isFocus,
  isDisabled,
  errorMessage,
  successMessage,
  name,
}) => (
  <InputFieldContainer>
    <InputFieldName>{name}</InputFieldName>
    <InputContainer
      isFocus={isFocus}
      isValid={isValid}
      disabled={isDisabled}
      isSuccess={isSuccess}
    >
      <InputLabel>{label}</InputLabel>
      <input placeholder={placeholder} disabled={isDisabled} />
      {errorMessage && (
        <ErrorContainer>
          <p>{errorMessage} </p>
          <ErrorBar />
        </ErrorContainer>
      )}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
    </InputContainer>
  </InputFieldContainer>
);

const InputFieldContainer = styled.div`
  display: flex;
  margin-block-end: 40px;
  align-items: center;
`;
const InputFieldName = styled.h4`
  inline-size: 166px;
`;

const InputContainer = styled.div<{
  isFocus: boolean | undefined;
  isValid: boolean | undefined;
  disabled: boolean | undefined;
  isSuccess: boolean | undefined;
}>`
  display: flex;
  flex-direction: column;

  input {
    inline-size: 220px;
    block-size: 50px;
    background-color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fonts.gotham};
    border: 1px solid ${(props) => props.theme.colors.alto};
    text-indent: 20px;
    ${(props) =>
      props.isFocus && `border: 1px solid ${props.theme.colors.slateLight};`}
    ${(props) =>
      props.disabled &&
      `border: 1px solid #45546466; 
      opacity:0.6;`}
    ${(props) =>
      props.isValid &&
      `border: 1px solid ${props.theme.colors.success};
      `}
    ${(props) =>
      props.isValid === false &&
      `border: 1px solid ${props.theme.colors.guardsmanRed};
      `}
    &::placeholder {
      ${(props) => props.isValid && `color: ${props.theme.colors.success};`}
      ${(props) =>
        props.isValid === false && `color: ${props.theme.colors.guardsmanRed};`}
          ${(props) => props.isSuccess && `color: ${props.theme.colors.fiord};`}
    }
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.slateLight};
      outline: none !important;
    }

    ${(props) => props.theme.mq.tablet} {
      inline-size: 280px;
    }
  }
`;

const InputLabel = styled.label`
  font-family: ${(props) => props.theme.fonts.gotham};
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  margin-block-end: 8px;
`;

const ErrorContainer = styled.div`
  block-size: 36px;
  background-color: ${(props) => props.theme.colors.porcelain};
  padding-inline-start: 19px;
  position: relative;
  display: flex;
  align-items: center;
  margin-block-start: 10px;
  p {
    color: ${(props) => props.theme.colors.guardsmanRed};
  }
`;

const ErrorBar = styled.div`
  block-size: 36px;
  inline-size: 5px;
  background-color: ${(props) => props.theme.colors.guardsmanRed};
  position: absolute;
  top: 0;
  left: 0;
`;

const SuccessMessage = styled.p`
  color: ${(props) => props.theme.colors.success};
  margin-inline-start: 24px;
`;

export default InputField;
