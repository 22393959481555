import React from 'react';
import styled from 'styled-components';
import Checkbox, { ICheckboxProps } from './Checkbox';
import Dropdown, { IDropdownData } from './Dropdown';
import InputField, { IInputFieldProps } from './InputField';
import RadioButton, { IRadioButtonProps } from './RadioButton';

const formFields: IInputFieldProps[] = [
  {
    name: 'Active',
    label: 'Label',
    placeholder: 'Input Field Text',
  },

  {
    name: 'Focus',
    placeholder: 'Focus',
    isFocus: true,
  },

  {
    name: 'Disabled',
    placeholder: 'Disabled',
    isDisabled: true,
  },

  {
    name: 'Valid',
    placeholder: 'Valid State',
    isValid: true,
  },

  {
    name: 'Success',
    placeholder: 'Success Message',
    isSuccess: true,
    successMessage: 'Detailed success message.',
  },

  {
    name: 'Invalid',
    placeholder: 'Invalid',
    isValid: false,
  },
  {
    name: 'Invalid',
    placeholder: 'Error Message',
    isValid: false,
    errorMessage: 'Detailed error message.',
  },
];

const dropdowns: IDropdownData[] = [
  {
    placeholder: 'Select',
    name: 'Active',
    isActive: true,
  },

  {
    placeholder: 'Selected Item',
    name: 'Disabled',
    isDisabled: true,
  },

  {
    placeholder: 'Invalid',
    name: 'Invalid',
    isValid: true,
  },

  {
    placeholder: 'Selected Item',
    name: 'Expanded',
    isExpanded: true,
    isOpen: true,
  },
];

const checkboxes: ICheckboxProps[] = [
  {
    id: '1',
    label: 'Active',
    name: 'Checkbox',
  },
  {
    id: '2',
    label: 'Disabled',
    isDisabled: true,
    name: 'Checkbox - Disabled',
  },
];

const radioButtons: IRadioButtonProps[] = [
  {
    id: '1',
    label: 'Active',
    name: 'Radio - Active',
  },
  {
    id: '2',
    label: 'Disabled',
    isDisabled: true,
    name: 'Radio - Disabled',
  },
];

const Inputs = () => (
  <>
    <Container>
      <h3>Form Fields</h3>
      {formFields.map((input, index) => (
        <InputField {...input} key={index} />
      ))}
    </Container>
    <Container>
      <h3>Dropdowns</h3>
      {dropdowns.map((dropdown, index) => (
        <Dropdown data={dropdown} key={index} />
      ))}
    </Container>
    <Row>
      <Column>
        <h4>Checkboxes</h4>
        {checkboxes.map((checkbox) => (
          <>
            <Checkbox {...checkbox} id={checkbox.id} />
            <p>{checkbox.name}</p>
          </>
        ))}
      </Column>

      <Column>
        <h4>Radio Buttons</h4>
        {radioButtons.map((radioButton) => (
          <>
            <RadioButton {...radioButton} id={radioButton.id} />
            <p>{radioButton.name}</p>
          </>
        ))}
      </Column>
    </Row>
  </>
);
const Container = styled.div`
  h3 {
    margin-block-end: 45px;
  }
`;

const Row = styled.div`
  margin-block-start: 200px;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.mq.tablet} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  &:first-child {
    flex-grow: 2;
  }
`;

export default Inputs;
