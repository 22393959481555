import React from 'react';
import styled from 'styled-components';
import { IButtonProps } from './Button';
import ButtonWrapper from './ButtonWrapper';

const fireButton: IButtonProps = {
  label: 'Button Label',
  outline: false,
  variant: 'light',
};
const darkButton: IButtonProps = {
  label: 'Button Label',
  outline: false,
  variant: 'dark',
};
const outlineLightButton: IButtonProps = {
  label: 'Button Label',
  outline: true,
  variant: 'light',
};
const outlineDarkButton: IButtonProps = {
  label: 'Button Label',
  outline: true,
  variant: 'dark',
};
const disabledLightButton: IButtonProps = {
  label: 'Disabled',
  outline: false,
  variant: 'light',
  isDisabled: true,
};
const disbledDarkButton: IButtonProps = {
  label: 'Disabled',
  outline: false,
  variant: 'dark',
  isDisabled: true,
};

const buttons = [
  {
    title: 'BUTTONS - VARIANT 1',
    description: 'Large Button',
    button: fireButton,
  },
  {
    title: 'BUTTONS - VARIANT 2',
    description: 'Large Button',
    button: darkButton,
  },
  {
    title: 'OUTLINE BUTTONS - ON LIGHT',
    description: 'Outline Button',
    button: outlineLightButton,
  },
  {
    title: 'OUTLINE BUTTONS - ON DARK',
    description: 'Outline Button',
    button: outlineDarkButton,
  },
  {
    title: 'DISABLEd BUTTONS - ON LIGHT',
    description: 'Disabled Button - On Light',
    button: disabledLightButton,
  },
  {
    title: 'DISABLE BUTTONS - ON DARK',
    description: 'Disabled Button - On Dark',
    button: disbledDarkButton,
  },
];

const Buttons: React.FC = () => (
  <ButtonsContainer>
    {buttons.map((button, index) => (
      <ButtonWrapper key={index} {...button} />
    ))}
  </ButtonsContainer>
);

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 1fr);
  row-gap: 20px;

  ${(props) => props.theme.mq.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: 40px;
  }
`;

export default Buttons;
