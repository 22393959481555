import React from 'react';
import ColorsGrid, { IColors } from './ColorsGrid';

const colors: IColors[] = [
  {
    name: 'Gray Light 1',
    codes: {
      HEX: '#D5D5D5',
    },
  },
  {
    name: 'Gray Light 2',
    codes: {
      HEX: '#F4F5F6',
    },
  },
  {
    name: 'Gray Light 3',
    codes: {
      HEX: '#F0F0F0',
    },
  },
];

const GrayWebColors = () => <ColorsGrid colors={colors} />;

export default GrayWebColors;
