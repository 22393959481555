import React from 'react';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from '../../../icons';

export interface ILinkProps {
  label: string;
  linkText: string;
  iconDirection: 'left' | 'right';
}

const ButtonLink: React.FC<ILinkProps> = ({
  label,
  linkText,
  iconDirection,
}) => (
  <LinkContainer>
    <LinkText iconDirection={iconDirection}>
      {linkText}
      {/*@ts-expect-error: we are auto-generating React components from svg files*/}
      {iconDirection === 'left' ? <ArrowLeft /> : <ArrowRight />}
    </LinkText>
    <p>{label}</p>
  </LinkContainer>
);

const LinkContainer = styled.div`
  p {
    font-size: 10px;
    margin-block-end: 14px;
    margin-block-start: 9px;
  }
`;

const LinkText = styled.a<{ iconDirection: 'left' | 'right' }>`
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 14px;
  letter-spacing: 0.2em;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  color: ${(props) => props.theme.colors.fiord};
  path {
    fill: ${(props) => props.theme.colors.fiord};
  }
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  ${(props) =>
    props.iconDirection === 'left' && `flex-direction: row-reverse; `}

  &:hover {
    gap: 10px;
    color: ${(props) => props.theme.colors.fireDark};
    path {
      fill: ${(props) => props.theme.colors.fireDark};
    }
  }
`;

export default ButtonLink;
